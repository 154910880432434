/* eslint-disable */
import {ASession} from '../../common/ASession'
import { Operator, Technology, Bandwidth, CustomerType, BuildingType } from '../FbbEnum'

import * as wording from '../../../common/js/wording'
import {SpecialOffer} from '../SpecialOffer'
import {API} from '../../../data/API';
import { SpecialOfferGroup } from '../SpecialOfferGroup'

export class Plan extends ASession {
    
    protected sessionKey:string = "Plan"

    protected planId:string = ""
    protected operator:Operator = Operator.hkbn
    protected planCode:string = ""
    protected marketingCode:string = ""
    protected ldCode:string = ""

    protected effectivePrice:Number = 0
    protected originalPrice:Number = 0
    protected contractPeriod:Number = 0
    protected techNology:Technology = Technology.GP
    protected bandwidth:Bandwidth = Bandwidth.b1000
    protected customerType:CustomerType = CustomerType.MASS
    protected channel:string = ""

    protected apiJson:string = ""
    protected tncJson:any = null
    protected gaJson:string = ""

    protected promoCode:string = ""

    protected mandatorySpecialOffers:Array<SpecialOffer> = [];
    protected optionalSpecialOffersGroup:Array<SpecialOfferGroup> = [];

    protected webParam:any = null //20201109

//=================================================================================

    protected getData() : any {

        var mandatorySpecialOffersJson = []
        for(var anOffer of this.mandatorySpecialOffers ){
            mandatorySpecialOffersJson.push(anOffer.convertToJson())
        }

        var optionalSpecialOffersJson = []
        for(var anOfferGroup of this.optionalSpecialOffersGroup ){
            optionalSpecialOffersJson.push(anOfferGroup.convertToJson())
        }

        var json = {
            planId: this.planId,
            operator: this.operator,
            planCode: this.planCode,
            
            marketingCode: this.marketingCode,
            ldCode: this.ldCode,
            effectivePrice: this.effectivePrice,
            originalPrice: this.originalPrice,
            contractPeriod: this.contractPeriod,

            techNology: this.techNology,
            bandwidth: this.bandwidth,
            customerType: this.customerType,
            channel: this.channel,
            apiJson:this.apiJson,
            tncJson:this.tncJson,
            gaJson: this.gaJson,

            mandatorySpecialOffers: mandatorySpecialOffersJson,
            optionalSpecialOffers:optionalSpecialOffersJson,
            promoCode:this.promoCode,
            webParam:this.webParam,

        }

        //log.ot('Plan getData', json)
        return json

    }
    protected setData(data: any) {
        
        this.mandatorySpecialOffers = []
        var mandatorySpecialOffersJson = data.mandatorySpecialOffers ?? []
        for(var json of mandatorySpecialOffersJson ){
            var o = new SpecialOffer()
            o.convertFromJson(json)
            this.mandatorySpecialOffers.push(o)
        }

        this.optionalSpecialOffersGroup = []
        var optionalSpecialOffersJson = data.optionalSpecialOffers ?? []
        for(var json2 of optionalSpecialOffersJson ){
            var g = new SpecialOfferGroup()
            g.convertFromJson(json2)
            this.optionalSpecialOffersGroup.push(g)
        }

        this.planId = data.planId ?? ""
        this.operator = data.operator ?? Operator.hkbn
        this.planCode = data.planCode ?? ""
        this.marketingCode = data.marketingCode ?? ""
        this.ldCode = data.ldCode ?? ""

        this.effectivePrice = data.effectivePrice ?? 0
        this.originalPrice = data.originalPrice ?? 0
        this.contractPeriod = data.contractPeriod ?? 0
        this.techNology = data.techNology ?? Technology.GP
        this.bandwidth = data.bandwidth ?? Bandwidth.b1000

        this.customerType = data.customerType ?? CustomerType.MASS
        this.channel = data.channel ?? ""
        this.apiJson = data.apiJson ?? {}
        this.gaJson = data.gaJson ?? {}
        this.tncJson = data.tncJson ?? {}

        this.promoCode = data.promoCode ?? ''

        this.webParam = data.webParam ?? {} //20201109

        /*
        data.technology?.forEach(aTech => {
            if ( aTech.type in Technology ){
                var techObj = {technology: aTech.type, 
                                haveCoverage: aTech.haveCoverage ?? false, havePort: aTech.havePort ?? false}
                this.technologyList.push(techObj)
            }
        });
        */

    }
    
    protected getDefaultData() : any {
        return {
        }
    }

    public getPlanId() : string {
        return this.planId
    }

    public parseApiResult(json:any){

        this.apiJson = json
        this.planId = json['id'] ?? ''
        this.operator = API.parseOperator(json['operator'])
        this.planCode = json['planCode'] ?? ''
        this.marketingCode = json['marketingCode'] ?? ''
        this.ldCode = json['ldCode'] ?? ''

        this.effectivePrice = json['effPrice'] ?? 0
        this.originalPrice = json['oriPrice'] ?? 0
        this.contractPeriod = json['contractPeriod'] ?? 0

        this.channel = json['channel'] ?? ''
        this.techNology = API.parseTechnology(json['technology'] ?? '')
        this.bandwidth = API.parseBandwidth(json['bandwidth'] ?? '')
        this.customerType = API.parseCustomerType(json['customerType'] ?? '')

        this.webParam = json['_web_param'] ?? {}

    }

    public getCustomerType() : CustomerType{
        return this.customerType
    }

    public setTncJson(json:any){
        let tempJson = json || {}
        if(!json.hasOwnProperty("tncList")){
            tempJson = {
                tncList: json
            }
        }
        this.tncJson = tempJson
    }


    public getPlanCode() : string {
        return (this.planCode ?? '') + ''
    }

    public getLdCode() : string {
        return (this.ldCode ?? '') + ''
    }

    public getMarketingCode() : string {
        return (this.marketingCode ?? '') + ''
    }

    public getOperator() : Operator {
        return this.operator
    }

    public getDisplayName(lang:string | null) : string {
        var targetLang = lang ?? wording.getLang()
        if ( this.apiJson == null ) return ''
        return (this.apiJson['planDisplayName'] ?? {})[targetLang] ?? ''
    }

    public getApiJson() : any {
        return this.apiJson ?? {}
    }

    public getGAJson() : string {
        return this.gaJson ?? {}
    }

    public setGAJson(j: string) {
        this.gaJson = j
    }

    public getEffectivePrice() : Number {
        return this.effectivePrice
    }

    public getOriginalPrice() : Number {
        return this.originalPrice
    }

    public getContractPeriod() : Number {
        return this.contractPeriod
    }

    public setMandatorySpecialOfferList(list:Array<SpecialOffer>){
        this.mandatorySpecialOffers = list
    }

    public getMandatorySpecialOfferList() : Array<SpecialOffer>{
        return this.mandatorySpecialOffers
    }

    public setPromoCode(code:string) {
        this.promoCode = code
    }

    public getPromoCode() : string {
        return this.promoCode + ''
    }

    public setOptionalSpecialOfferList(list:Array<SpecialOfferGroup>){
        this.optionalSpecialOffersGroup = list
    }

    public getOptionalSpecialOfferList() : Array<SpecialOfferGroup>{
        return this.optionalSpecialOffersGroup
    }

    public getBandwidth() : Bandwidth {
        return this.bandwidth
    }

    public getPlanImagePath(lang:string | null) : string {
        var targetLang = lang ?? wording.getLang()
        if ( this.apiJson == null ) return ''
        var name = (this.apiJson['planImagePath'] ?? {})[targetLang] ?? ''
        if ( name.length == 0 ){
            return name
        }
        return name
    }

    public getInstallationFee() : Number{
        var fee = this.apiJson['installationFee'] ?? 0
        return fee
    }

    public getBundledService() : string{
        var se = this.apiJson['bundledService'] ?? ''
        return se
    }

    public getTNCJson() : any {
        return this.tncJson['tncList'] ?? {}
    }

    public getWebParam() : any {
        return this.webParam ?? {}
    }

    public getServerTimestamp() : any{
        var webParam = this.getWebParam()
        return webParam['server_timestamp'] ?? ''
    }

    public setServerTimestamp(timestamp:String) : any{
        this.webParam['server_timestamp'] = timestamp
    }

    public getBuildingType() : BuildingType {

        var type = (this.apiJson['buildingType'] ?? "").toLowerCase()
        if ( type == 'private' ){
            return BuildingType.Private
        }
        else if ( type == 'public' ){
            return BuildingType.Public
        }
        else if ( type == 'village' ){
            return BuildingType.Village
        }
        else{
            return BuildingType.Private
        }
    }

    public getPlanTagList() : any {
        return this.apiJson['tagList'] ?? []
    }

    public getTechnology() : Technology {
        var type = (this.apiJson['technology'] ?? "").toLowerCase()
        if ( type == 'gp' || type == 'gpon' ){
            return Technology.GP
        }
        else if ( type == 'me' ){
            return Technology.ME
        }
        else if ( type == 'dos' ){
            return Technology.DOS
        }
        else{
            return Technology.GP
        }
    }

    public getGTMMarketingCodeList() : string{
        var gtmMarketingCodeList = this.getMarketingCode()
        var mandaList = this.getMandatorySpecialOfferList()
        for(var aList of mandaList){
            gtmMarketingCodeList += ',' + aList.getMarketingCode()
        }
        var optionList = this.getOptionalSpecialOfferList()
        for(var aGroup of optionList){
            var selected = aGroup.getSelectedSpecialOffer()
            gtmMarketingCodeList += ',' + (selected?.getMarketingCode() ?? '')
        }
        return gtmMarketingCodeList
    }


//=================================================================================





}