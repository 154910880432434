import { AddOn } from './AddOn'
import { AddOnType } from '../FbbEnum'

//!!!!! metehod createGAItem depends on this list. Please check against.
export class AddOnMyTV extends AddOn{

    static readonly codeInfo = {
        equipList:"TVB,1"
    }

    constructor(){
        super()
        this.key = 'AddOnMyTV'
        
        this.boxIconEn = ''
        this.boxIconZh = ''
        this.boxNameEn = 'My TV Super'
        this.boxNameZh = 'My TV Super'
        this.boxAmount = 100
        this.hasRedCounter = false
        /*
        this.landingIcon['en'] = '/img/addon/router.png'
        this.landingIcon['zh'] = '/img/addon/router.png'

        this.landingTitle['en'] = ''
        this.landingTitle['zh'] = ''
        this.landingDesc1['en'] = ''
        this.landingDesc1['zh'] = ''
        this.landingDesc2['en'] = ''
        this.landingDesc2['zh'] = ''
        */
        this.type = AddOnType.SINGLE
        
    }

    public isAddOnSelected() : boolean{
        return this.boxCount > 0
    }

    /*
    {
      item_id: "fbb-addon-kkbox",
      item_name: "KKBOX (無合約)",
      currency: "HKD",
      item_brand: "KKBOX",
      item_category: "Services",
      item_list_id: "fbb",
      item_list_name: "FBB Portal",
      price: 49.00,
      quantity: 1
    }
    */
    //price handling reference OrderBox.
    public createGAItem(lang: string) : any{

        var main = {
            "item_id": "fbb-addon-mytv",
            "item_name": this.getName(lang),
            "currency": "HKD",
            "item_brand": "MYTV",
            "item_category": "Services",
            "item_list_id": "fbb",
            "item_list_name": "FBB Portal",
            "price": this.getAmount(null, null),
            "quantity": 1,
        }
        return main
    }

}