import { Component } from 'react';

class PageNotFound extends Component {

	/*
	constructor(props) {
		super(props);
	}
	*/

	componentDidMount() {
		//this.props.history.goBack()
	}

	componentWillUnmount() {
	}
	

	render() {
		return (
            '' //'404'
		);
	}
}

export default PageNotFound;