import { AddOn } from './AddOn'
import { AddOnType } from '../FbbEnum'


//!!!!! metehod createGAItem depends on this list. Please check against.
export const NortonOptionList = [
    
    {
        "order": 0,
        "vasCode": "FBNP1",
        "marketCode": "MWV54",  //ori_marketCode: MWV54
        "ldCode": "LDA24M48",
        "key": "norton-family-12-contract",
        "fee": 48,
        "gaClickEvent": "norton_smart_cyber_combo",
        "title": {
            "en": "SmarT Cyber Protection Comprehensive Family Combo (5 Devices)/24 months contract<br/><div class=\"font14\">Enjoy free first 3-months for 24 months contract</div>",
            "zh": "「網絡防護保」全方位家庭組合（5個裝置)/24個月合約<br/><div class=\"font14\">簽訂24個月合約即享首3個月免費</div>"
        },
        "details": {
            "en": "<ul class='discList2'><li>Norton Security – Provide tech defends against viruses, spyware, phishing web/ app and other online threats </li><li>Norton Secure VPN - Bank-grade encryption ensures information you send and receive is secure </li><li>Norton Family – Family features so your kids can explore their connected world safely </li><li class='bold color-r'>Enjoy 50% off for 24 months contract</li></ul>",
            "zh": "<ul class='discList2'><li>諾頓網絡安全 - 抵禦病毒、間諜程式、惡意軟體和其他線上威脅</li><li>諾頓安全VPN - 使用銀行等級加密技術，協助確保傳送及接收的資訊安全且私密</li><li>諾頓家長防護網 - 提供家庭防護網安全功能，讓孩子能夠安全地探索網路世界</li><li class='bold color-r'>簽訂24個月合約即享半價優惠</li></ul>"
        },
        "emailContent":{
            "en": "Norton Service - SmarT Cyber Protection Comprehensive Family Combo (24 months) – HK$48 per month <br/>(Norton activation SMS will be sent to your mobile number when the fibre broadband service has activated)",
            "zh": "諾頓服務 – 「網絡防護保」全方位家庭組合 (24個月合約) – 每月$48 <br/>(光纖寬頻服務生效後， Norton啟動服務短訊將發送到你的流動電話號碼)"
        }
    },
    {
        "order": 1,
        "vasCode": "FBNS1",
        "marketCode": "MWV52",
        "ldCode": "LDA24M20",
        "key": "norton-1-deivce",
        "fee": 20,
        "gaClickEvent": "norton_security",
        "title": {
            "en": "Norton Security (1 Device)/24 months contract",
            "zh": "諾頓網絡安全(1個裝置)/24個月合約"
        },
        "details": {
            "en": "<ul class='discList2'><li>Norton Security – Provide tech defends against viruses, spyware, phishing web/ app and other online threats </li></ul>",
            "zh": "<ul class='discList2'><li>諾頓網絡安全 - 抵禦病毒、間諜程式、惡意軟體和其他線上威脅</li></ul>"
        },
        "emailContent":{
            "en": "Norton Service - Norton Security (24 month contract) – HK$20 per month <br/>(Norton activation SMS will be sent to your mobile number when the fibre broadband service has activated)",
            "zh": "諾頓服務 – 諾頓網絡安全 (24個月合約) – 每月 $20 <br/>(光纖寬頻服務生效後， Norton啟動服務短訊將發送到你的流動電話號碼)"
        }
    },
    {
        "order": 2,
        "vasCode": "FBNV1",
        "marketCode": "MWV53",  // "ori_marketCode": "MWV53",        
        "ldCode": "LDA24M30",
        "key": "norton-vpn-1-deivce",
        "fee": 30,
        "gaClickEvent": "norton_security_vpn",
        "title": {
            "en": "Norton Security & Norton Secure VPN (1 Device)/24 months contract",
            "zh": "諾頓網絡安全及諾頓安全VPN(1個裝置)/24個月合約"
        },
        "details": {
            "en": "<ul class='discList2'><li>Norton Security – Provide tech defends against viruses, spyware, phishing web/ app and other online threats </li><li>Norton Secure VPN - Bank-grade encryption ensures information you send and receive is secure </li><li class='bold color-r'>Enjoy 50% off for 24 months contract</li></ul>",
            "zh":"<ulclass='discList2'><li>諾頓網絡安全 - 抵禦病毒、間諜程式、惡意軟體和其他線上威脅</li><li>諾頓安全VPN - 使用銀行等級加密技術，協助確保傳送及接收的資訊安全且私密</li><liclass='boldcolor-r'>簽訂24個月合約即享半價優惠</li></ul>"
        },
        "emailContent":{
            "en": "Norton Service - Norton Security & Norton Secure VPN (24 month contract) – HK$30 per month <br/>(Norton activation SMS will be sent to your mobile number when the fibre broadband service has activated)",
            "zh": "諾頓服務 – 諾頓網絡安全及諾頓安全VPN (24個月合約) – 每月 $30 <br/>(光纖寬頻服務生效後， Norton啟動服務短訊將發送到你的流動電話號碼)"
        }
    },
    {
        "order": 3,
        "vasCode": "FBNF1",
        "marketCode": "MWV51",
        "ldCode": "LDA24M20",
        "key": "norton-family",
        "fee": 20,
        "gaClickEvent": "norton_family",
        "title": {
            "en": "Norton Family (1 Device)/24 months contract",
            "zh": "諾 頓 家 長 防 護 網 (1 個 裝 置 )/24個月合約"
        },
        "details": {
            "en": "<ul class='discList2'><li>Norton Family – Family features so your kids can explore their connected world safely </li></ul>",
            "zh": "<ul class='discList2'><li>諾 頓 家 長 防 護 網 - 提 供 家 庭 防 護 網 安 全 功 能 ， 讓 孩 子 能 夠 安 全 地 探 索 網 路 世 界 </li></ul>"
        },
        "emailContent":{
            "en": "Norton Service - Norton Family (24 month contract) HK$20 per month <br/>(Norton activation SMS will be sent to your mobile number when the fibre broadband service has activated)",
            "zh": "諾頓服務 – 諾頓家長防護網 (24個月合約) 每月 $20 <br/>(光纖寬頻服務生效後， Norton啟動服務短訊將發送到你的流動電話號碼)"
        }
    }
]


export enum AddOnNortonPlan{
    NONE = -1,
    Plan1 = 0,
    Plan2 = 1,
    Plan3 = 2,
    Plan4 = 3,    
    //Plan5 = 4,    
}

export class AddOnNorton extends AddOn{

    protected selectedPlanList:Array<any> = []
    protected maxPlanSelected:number = 1;
    protected planIndex:AddOnNortonPlan = AddOnNortonPlan.NONE //Helen, please rename if needed
    protected plan:any = null;

    constructor(){
        super()
        this.key = 'AddOnNorton'
        
        this.boxIconEn = ''
        this.boxIconZh = ''
        this.boxNameEn = 'Norton'
        this.boxNameZh = 'Norton'
        this.boxAmount = 20
        this.hasRedCounter = false
        /*
        this.landingIcon['en'] = '/img/addon/router.png'
        this.landingIcon['zh'] = '/img/addon/router.png'

        this.landingTitle['en'] = ''
        this.landingTitle['zh'] = ''
        this.landingDesc1['en'] = ''
        this.landingDesc1['zh'] = ''
        this.landingDesc2['en'] = ''
        this.landingDesc2['zh'] = ''
        */
        this.type = AddOnType.CHOOSE1
        
    }

    public isAddOnSelected() : boolean{
        return this.planIndex !== AddOnNortonPlan.NONE
    }

    public setPlanList(planIndex:AddOnNortonPlan){
        this.planIndex = planIndex
        if(this.selectedPlanList.length >= this.maxPlanSelected){
            this.selectedPlanList.pop()
        }
        this.setPlan(NortonOptionList[planIndex])
        this.selectedPlanList.push(this.plan)     
    }

    public setDefaultPlan(){
        this.setPlanList(AddOnNortonPlan.Plan1)
        this.setSelectedCount(1)
    }

    public unselectPlan(){
        this.planIndex = AddOnNortonPlan.NONE
        this.selectedPlanList = []
        this.plan = null;
    }

    public getPlanList() : Array<any> {
        //return this.plan
        return this.selectedPlanList;
    }
    public setPlan(plan:any){
        this.plan = plan;
    }
    public getPlan() : any {
        return this.plan
    }

    public getPlanIndex() : number {
        //return this.plan
        return this.planIndex;     
    }

    public getPlanByIndex(planIndex: number) : any {
        if ( planIndex === AddOnNortonPlan.NONE ){
            return null;
        }
        else { 
            return NortonOptionList[planIndex]
        }  
    }

    public getPlanPrice() : number {
        if ( this.planIndex === AddOnNortonPlan.NONE ){
            return 0;
        }
        else { 
            return (this.plan ?? {}).fee ?? 0
        }  
    }

    public getPlanVasCode() : string {
        if ( this.planIndex === AddOnNortonPlan.NONE ){
            return ""
        }
        else{
            return this.plan.vasCode
        }  
    }


    public getPlanMktCode() : string {
        if ( this.planIndex === AddOnNortonPlan.NONE ){
            return ""
        }
        else{
            return this.plan.marketCode
        }
    }


    public getPlanLdCode() : string {
        if ( this.planIndex === AddOnNortonPlan.NONE ){
            return ""
        }
        else{
            return this.plan.ldCode
        }
    }

    public getOptionEmailContent(lang:string) : any{
        let emailContent = []
        if ( this.planIndex === AddOnNortonPlan.NONE ){}
        else { 
            emailContent.push(this.plan.emailContent[lang])
        }  
        return emailContent
    }

    /*
    {
      item_id: "fbb-addon-kkbox",
      item_name: "KKBOX (無合約)",
      currency: "HKD",
      item_brand: "KKBOX",
      item_category: "Services",
      item_list_id: "fbb",
      item_list_name: "FBB Portal",
      price: 49.00,
      quantity: 1
    }
    */
    //price handling reference OrderBox.
    public createGAItem(lang: string) : any{

        var selected = this.getPlanList()[0]
        var cutTitle = selected['title'][lang]
        if ( cutTitle.indexOf('<br/>') != -1 ){
            cutTitle = cutTitle.substring(0, cutTitle.indexOf('<br/>'))
        }
        var main = {
            "item_id": "fbb-addon-norton",
            "item_name": cutTitle,
            "currency": "HKD",
            "item_brand": "NORTON",
            "item_category": "Services",
            "item_list_id": "fbb",
            "item_list_name": "FBB Portal",
            "price": selected['fee'],
            "quantity": 1,
        }
        return main
    }
}