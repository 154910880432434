import * as util from './util'
import * as log from './appLogger'
import {Config} from '../../data/Config'

export const ENGLISH = "en"
export const CHINESE = "zh"
export const SESSION_NAME_LANG = "applang"
var lang: string | null = ENGLISH

//================== S. Language related ==================//

export function getLang() : string{
    return '' + lang
}

export function setLang(theLang:string){

    if ( theLang === ENGLISH || theLang === CHINESE){
        lang = theLang
    }
    else{
        lang = ENGLISH
    }
    saveLang(lang)
}

export function toggleLang(){
    if ( lang === ENGLISH )
        lang = CHINESE;
    else
        lang = ENGLISH;
}

//priority: param > session > default
export function loadLang(){

    
    lang = getStoredLang()
    //if ( lang == null ) {
        var anotherIsEn = window.location.href.indexOf('/en/') != -1
        var anotherIsZh = window.location.href.indexOf('/tc/') != -1
        if ( anotherIsEn ){
            setLang(ENGLISH)
        }
        else if ( anotherIsZh ){
            setLang(CHINESE)
        }
        else{
            lang = getStoredLang()
        }
        if ( lang == null ){
            setLang(ENGLISH)
        }
    //}
    var params = util.getUrlVars(null)
    //if ( params.lang ){
    //    setLang(params.lang)
    //}


}

function saveLang(theLang:string){
    sessionStorage.setItem(SESSION_NAME_LANG, theLang)
}

function getStoredLang(){
    var infoString = sessionStorage.getItem(SESSION_NAME_LANG)
    if ( infoString ){
        log.it('wording', 'getStoredLang')
        return infoString
    }
    return null
}



//================== E. Language related ==================//

export function get(json:any, targetLang:string | null) {    
    try{
        if ( targetLang != null && targetLang !== undefined ){
            return json[targetLang ?? '']
        }
        else{
            return json[lang ?? '']
        }
    }catch(e){
        return '';
    }
}

export function getByKey(jsonArr:Array, keyId:string) {
    try{
        let json = jsonArr.find(function(item){
            return item.key === keyId
        })
        return json[lang ?? '']
    }catch(e){
        return '';
    }
}


export function getWording(filePath: string){
    var fileLink = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        fileLink =  filePath+"?t="+new Date().getTime()
    } else {
        fileLink = Config.filepathname +filePath+"?t="+new Date().getTime()
    }
    return fetch(fileLink)
    .then((response) => response.json())
    .then((wordingJson) => {
        return wordingJson;
    })
    .catch((error) => {
        console.error("Load file: " + filePath + " \n" + error);
    });
}

export function getFile(filePath: string){
    var fileLink = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        fileLink =  filePath+"?t="+new Date().getTime()
    } else {
        fileLink = Config.filepathname +filePath+"?t="+new Date().getTime()
    }
    return fetch(fileLink)
    .then((response) =>{
        return response.text();
    })
    .catch((error) => {
        console.error("Load file: " + filePath + " \n" + error);
    });
}