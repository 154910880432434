
//import { AddOn } from '../addon/AddOn'
import { AddOnHometel } from '../addon/AddOnHometel'
import { ASession } from '../../common/ASession'
import { AddOnKKBox, AddOnKKBoxPlan } from '../addon/AddOnKKBox'
import { AddOnMyTV } from '../addon/AddOnMyTV'
import { AddOnNorton, AddOnNortonPlan } from '../addon/AddOnNorton'
import { AddOnRouter } from '../addon/AddOnRouter'
import * as log from '../../../common/js/appLogger'
import { AddOn } from '../addon/AddOn'
import { AddOnUtil } from '../addon/AddOnUtil'
import { AddOnHomePhonePlus, AddOnHomePhonePlusPlan } from '../addon/AddOnHomePhonePlus'

export class AllAddOn extends ASession{

    protected sessionKey:string = "AllAddOnService" //selected add on service

    protected hometel:AddOnHometel | null = null
    protected kkbox:AddOnKKBox | null = null
    protected mytv:AddOnMyTV | null = null
    protected norton:AddOnNorton | null = null
    protected router:AddOnRouter | null = null
    protected homePhonePlus:AddOnHomePhonePlus | null = null


    constructor(){
        super()
        this.setData(this.getDefaultData())
    }

//=================================================================================
    public getData() : any {

        var hometelJson = this.hometel2Json()
        var kkboxJson = this.kkbox2Json()
        var mytvJson = this.mytv2Json()
        var nortonJson = this.norton2Json()
        var routerJson = this.router2Json()
        var homePhonePlusJson = this.homePhonePlus2Json()
        return {
            hometel: hometelJson,
            kkbox: kkboxJson,
            mytv: mytvJson,
            norton: nortonJson, //to be implemented
            router: routerJson,
            homePhonePlus: homePhonePlusJson,
        }
    }
    
    protected setData(data: any) {

        this.parseHometel(data)
        this.parseKKBox(data)
        this.parseMyTV(data)
        this.parseNotron(data)
        this.parseRouter(data)
        this.parseHomePhonePlus(data)
        
        //log.ot('all addon set data', data)
        //log.ot('all addon set data router', this.router)
        
    }

    protected getDefaultData() : any{
        return {
            hometel:{ selected: false, callForward:false, speedDial:false, doNotDisturb:false },
            kkbox:{ plan: AddOnKKBoxPlan.NONE },
            mytv:{ selected:false, },
            norton:{ plan:AddOnNortonPlan.NONE }, //to be implemented
            router:{ selected:false },
            homePhonePlus:{ plan:AddOnHomePhonePlusPlan.NONE }, //to be implemented
        }
    }

    public addAddon(addon:AddOn){
        //log.ot('--- addAddon', addon)
        if ( addon instanceof AddOnKKBox ){
            this.kkbox = addon
        }
        else if ( addon instanceof AddOnHometel ){
            this.hometel = addon
        }
        else if ( addon instanceof AddOnMyTV ){
            this.mytv = addon
        }
        else if ( addon instanceof AddOnNorton ){
            this.norton = addon
        }
        else if ( addon instanceof AddOnRouter ){
            this.router = addon
        }
        else if ( addon instanceof AddOnHomePhonePlus ){
            this.homePhonePlus = addon
        }
    }

    public getHometel() :AddOnHometel{
        // eslint-disable-next-line
        return this.hometel
    }

    public getHomePhonePlus() :AddOnHomePhonePlus{
        // eslint-disable-next-line
        return this.homePhonePlus
    }

    protected hometel2Json() :any{
        var hometelJson = {
            key: 'AddOnHometel',
            selected: false, 
            callForward:false, 
            speedDial:false, 
            doNotDisturb:false,   
            isNewNum: false,
            phoneNum: null,
            isReserved: false,        
            isApproved: false,
        }
        if ( this.hometel == null ){
            return hometelJson
        }
        if ( !this.hometel.getIsBasePlanSelected() ){
            return hometelJson
        }
        
        hometelJson.selected = this.hometel.getIsBasePlanSelected()
        hometelJson.callForward = this.hometel.isSelectedCallForward()
        hometelJson.speedDial = this.hometel.isSelectedSpeedDial()
        hometelJson.doNotDisturb = this.hometel.isSelectedDoNotDisturb()
        var tempDate = this.hometel.getData();
        hometelJson.isNewNum = tempDate.isNewNum
        hometelJson.phoneNum = tempDate.phoneNum
        hometelJson.isReserved = tempDate.isReserved
        hometelJson.isApproved = this.hometel.isHometelApproved()
        
        
        return hometelJson
    }

    protected kkbox2Json() :any{
        var json = { plan: AddOnKKBoxPlan.NONE }
        if ( this.kkbox == null ){
            return json
        }
        json.plan = this.kkbox.getPlanIndex()
        return json
    }

    protected mytv2Json() :any{
        var json = { selected: this.mytv?.isAddOnSelected() ?? false }
        return json
    }

    protected norton2Json() :any{
        var json = { plan: AddOnNortonPlan.NONE }
        if ( this.norton == null ){
            return json
        }
        json.plan = this.norton.getPlanIndex()
        return json
    }

    protected homePhonePlus2Json() :any{
        var json = { plan: AddOnHomePhonePlusPlan.NONE, config: {} }
        if ( this.homePhonePlus == null ){
            return json
        }
        json.plan = this.homePhonePlus.getPlanIndex()
        json.config = this.homePhonePlus.getConfigJson() ?? {}
        return json
    }

    protected router2Json() :any{
        var json = { selected: this.router?.isAddOnSelected() ?? false }
        return json
    }


    protected parseKKBox(data:any){

        //log.ot('allAddon parseKKBox', data.kkbox?.plan)
        if ( (data.kkbox?.plan ?? AddOnKKBoxPlan.NONE) !== AddOnKKBoxPlan.NONE ){
            this.kkbox = new AddOnKKBox()
            this.kkbox.setPlanList(data.kkbox.plan)
        }
        else{
            this.kkbox = null
        }
    }

    protected parseMyTV(data:any){
        if ( data.mytv?.selected ?? false ){
            this.mytv = new AddOnMyTV()
            this.mytv.setSelectedCount(1)
        }
        else{
            this.mytv = null
        }
    }

    protected parseRouter(data:any){
        if ( data.router?.selected ?? false ){
            this.router = new AddOnRouter()
            //log.it('..>>>', (data.router?.selected ?? false))
            this.router.setSelectedCount(1)
        }
        else{
            this.router = null
        }
    }

    protected parseNotron(data:any){
        if ( (data.norton?.plan ?? AddOnNortonPlan.NONE) !== AddOnNortonPlan.NONE ){
            this.norton = new AddOnNorton()
            this.norton.setPlanList(data.norton.plan)
        }
        else{
            this.norton = null
        }
    }


    protected parseHomePhonePlus(data:any){
        log.dt('[SPH7] AllAddOn parseHomePhonePlus', data )
        if ( (data.homePhonePlus?.plan ?? AddOnHomePhonePlusPlan.NONE) !== AddOnHomePhonePlusPlan.NONE ){
            this.homePhonePlus = new AddOnHomePhonePlus()
            this.homePhonePlus.setPlanList(data.homePhonePlus.plan)
        }
        else{
            this.homePhonePlus = null
        }
    }

    protected parseHometel(data:any){
        if (data.hometel?.selected ?? false){
            this.hometel = new AddOnHometel()
            this.hometel.setIsBasePlanSelected(true)
            if ( data.hometel.callForward ){
                this.hometel.setCallForward(true)
            }
            if ( data.hometel.speedDial ){
                this.hometel.setSpeedDial(true)
            }
            if ( data.hometel.doNotDisturb ){
                this.hometel.setDoNotDisturb(true)
            }
            
            this.hometel.parse({
                isNewNum: data.hometel.isNewNum,
                phoneNum: data.hometel.phoneNum,
                isReserved: data.hometel.isReserved,
                isApproved: data.hometel.isApproved,
            })
            
        }
        else{
            this.hometel = null
        }
    }

    public parseJson(addonJson:any, plan: any){
        var list = this.getAddonList()
        log.dt('[SPU7] AllAdOn parseJson', (plan ? plan.getApiJson() : ''))
        for(var addon of list){
            var j = AddOnUtil.findJson(addonJson, addon.getKey())
            addon.parseConfig(j)
            addon.switchConfig(plan ? (plan.getApiJson() ?? {})['planGroup'] ?? '' : null)
        }
    }


    public getAddonList() : Array<AddOn> {
        // eslint-disable-next-line
        var ary: Array<AddOn> = new Array()
        if ( this.hometel != null ){
            ary.push(this.hometel)
        }
        if ( this.kkbox != null ){
            ary.push(this.kkbox)
        }
        if ( this.mytv != null ){
            ary.push(this.mytv)
        }
        if ( this.norton != null ){
            ary.push(this.norton)
        }
        if ( this.router != null ){
            ary.push(this.router)
        }
        if ( this.homePhonePlus != null ){
            ary.push(this.homePhonePlus)
        }
        return ary
    }

    public getGTMAddOnList() : string {
        var addons = this.getAddonList() ?? []

        var gtmAddonList = ''
        for(var addon of addons ){
            if ( addon instanceof AddOnRouter ){
                gtmAddonList += (gtmAddonList.length > 0 ? ',' : '') + 'Router'
            }
            else if ( addon instanceof AddOnKKBox ){
                gtmAddonList += (gtmAddonList.length > 0 ? ',' : '') + 'KKBox'
            }
            else if ( addon instanceof AddOnNorton ){
                gtmAddonList += (gtmAddonList.length > 0 ? ',' : '') + 'Norton'
            }
            else if ( addon instanceof AddOnMyTV ){
                gtmAddonList += (gtmAddonList.length > 0 ? ',' : '') + 'MyTV'
            }
            else if ( addon instanceof AddOnHometel ){
                gtmAddonList += (gtmAddonList.length > 0 ? ',' : '') + 'Hometel'
            }
            else if ( addon instanceof AddOnHomePhonePlus ){
                gtmAddonList += (gtmAddonList.length > 0 ? ',' : '') + 'HomePhonePlus'
            }
        }
        return gtmAddonList
    }



    //=================================================================================



}