
import { CoverageType, BuildingType, Technology, Operator } from './FbbEnum'
import { IModelUtil } from '../common/IModelUtil'
//import * as log from '../../common/js/appLogger'

export class Coverage implements IModelUtil{
    
    protected isSuccess:boolean = false
    protected hkbnResultCode:string = ''
    protected operator:Operator
    protected coverageType:CoverageType //R / C
    protected buildingType:BuildingType //PUBLIC
    protected technologyList:Array<{technology: Technology, haveCoverage: boolean, havePort: boolean}> = [];   //GP

    protected bbPreinstalled:boolean = false     //NO API??????
    protected allowHolidayInstall:boolean = false //NO API??????

    protected allowDeferActivate:boolean = false

    protected haveHometelPort:boolean = false    //HKBN only

    constructor(_operator:Operator){
        this.operator = _operator

        this.isSuccess = false
        this.coverageType = CoverageType.Residential
        this.buildingType = BuildingType.Private
        this.technologyList = []

        this.bbPreinstalled = false
        this.allowHolidayInstall = false
        this.allowDeferActivate = false
        
        this.haveHometelPort = false
    }


    private parseHKBN(data:any){

        this.isSuccess = data.status === 'NORMAL'
        this.haveHometelPort = data.haveHometelPort ?? false
        this.hkbnResultCode = data.resultCode
    }

    private parseHGC(data:any){
        this.isSuccess = data.status === 'NORMAL'
        this.haveHometelPort = false
    }

    private parseICABLE(data:any){
        this.isSuccess = data.status === 'NORMAL'
        this.haveHometelPort = false
    }

    private parseCommon(data:any){
        
        /*
        "hkbnResult":{
            "status":"NO_COVERAGE", //NORMAL, or Reject reason, see resultCode.
            "coverageType":"R/C",
            "buildingType":"PUBLIC/PRIVATE/VILLAGE",
            "technology":[
                { "type": "ME", "haveCoverage": true, "havePort": true },
                { "type": "GP", "haveCoverage": true, "havePort": true },
                { "type": "DOS", "haveCoverage": true, "havePort": true }, //no such row if operator don't support
            ]
            "haveHometelPort": true,    //HKBN only
            "allowDeferActivate":false,
        },
        */
        this.coverageType = (data.coverageType ?? "_") === "C" ? 
                                   CoverageType.Commercial : CoverageType.Residential
        this.buildingType = BuildingType.Private                           
        switch(data.buildingType ?? "_"){
            case BuildingType.Private:
               this.buildingType = BuildingType.Private
               break;
            case BuildingType.Public:
               this.buildingType = BuildingType.Public
               break;
            case BuildingType.Village:
               this.buildingType = BuildingType.Village
               break;
        }
        this.allowDeferActivate = data.allowDeferActivate ?? false

        this.technologyList = []

        data.technology?.forEach(aTech => {
            if ( aTech.type in Technology ){
                var techObj = {technology: aTech.type, 
                                haveCoverage: aTech.haveCoverage ?? false, havePort: aTech.havePort ?? false}
                this.technologyList.push(techObj)
            }
        });
        
        this.bbPreinstalled = false //NO API??????
        this.allowHolidayInstall = false //NO API??????

    }

    public readyTechnologies() : Technology[] {

        var list:Technology[] = []
        for(var aTech of this.technologyList ){
            if ( aTech.haveCoverage && aTech.havePort ){
                list.push(aTech.technology)
            }
        }
        return list
    }

    public haveCoverage() : boolean {
        for(var aTech of this.technologyList ){
            if ( aTech.haveCoverage ){
                return true
            }
        }
        return false
    }

    public getHkbnResultCode() : string {
        return this.hkbnResultCode
    }

    public getBuildingType() : BuildingType {
        return this.buildingType
    }

    public havePort() : boolean {
        for(var aTech of this.technologyList ){
            if ( aTech.havePort ){
                return true
            }
        }
        return false
    }

//=================================================================================
    //interface IModelUtil
    
    parseApiResult(data: any): void {

        this.parseCommon(data)

        if ( this.operator === Operator.hkbn ){
            this.parseHKBN(data)
        }
        else if ( this.operator === Operator.hgc ){
            this.parseHGC(data)
        }
        else if ( this.operator === Operator.icable ){
            this.parseICABLE(data)
        }

    }

    convertFromJson(json:any) : void {

        if ( json == null ){
            return
        }

        this.isSuccess = json.isSuccess ?? false
        this.hkbnResultCode = json.hkbnResultCode ?? ''
        this.operator = json.operator ?? Operator.hkbn
        this.coverageType = json.coverageType ?? CoverageType.Residential
        this.buildingType = json.buildingType ?? BuildingType.Private
        this.bbPreinstalled = json.bbPreinstalled === 'Y'
        this.allowHolidayInstall = json.allowHolidayInstall === 'Y'
        this.allowHolidayInstall = json.allowHolidayInstall === 'Y'
        this.allowDeferActivate = json.allowDeferActivate === 'Y'
        this.haveHometelPort = json.haveHometelPort === 'Y'

        this.technologyList = []
        for(var aTechJson of json.technologyList){
            var techObj = {technology: aTechJson.technology, 
                haveCoverage: aTechJson.haveCoverage === 'Y', havePort: aTechJson.havePort === 'Y' }
            this.technologyList.push(techObj)
        }

    }

    convertToJson() : any {
        
        var techListJson = []
        for(var aTech of this.technologyList ){
            var aTechJson = {
                technology: aTech.technology,
                haveCoverage: aTech.haveCoverage ? 'Y' : 'N',
                havePort: aTech.havePort ? 'Y' : 'N',
            }
            techListJson.push(aTechJson)
        }

        var json = {
            isSuccess: this.isSuccess ? 'Y' : 'N',
            hkbnResultCode:this.hkbnResultCode,
            operator: this.operator,
            coverageType: this.coverageType,
            buildingType: this.buildingType,
            bbPreinstalled: this.bbPreinstalled ? 'Y' : 'N',
            allowHolidayInstall: this.allowHolidayInstall ? 'Y' : 'N',
            allowDeferActivate: this.allowDeferActivate ? 'Y' : 'N',
            haveHometelPort: this.haveHometelPort ? 'Y' : 'N',
            technologyList: techListJson
        }
 
        return json

    }

    getHaveHometelPort(){
        return this.haveHometelPort
    }
}