
import * as log from '../../common/js/appLogger'
import * as wording from '../../common/js/wording'
import {API} from "../../data/API"
import {Config} from '../../data/Config'

export class Error {

    static CAT_COMMON:string = "error_common"    //app will load as error_common.json

    static errorWordingList:any

    constructor(){
        Error.errorWordingList = {}
    }

    isLoaded(category:string) : boolean {
        if ( !Error.errorWordingList[category] ){
            return false
        }
        return true
    }

    load(category:string, callback:any){

        if ( this.isLoaded(category) ){
            if ( callback != null && callback !== undefined ){
                callback()
            }
            return
        }

        wording.getWording('/wording/Common/' + category + '.json').then(function (wordingJson){	//ErrorMessage.json
            Error.errorWordingList[category] = wordingJson
            if ( callback != null && callback !== undefined ){
                callback()
            }
        });
    }

    loadWithRef(category:string, callback:any, _thisRef:any){

        if ( this.isLoaded(category) ){
            if ( callback != null && callback !== undefined ){
                callback(_thisRef)
            }
            return
        }

        wording.getWording('/wording/Common/' + category + '.json').then(function (wordingJson){	//ErrorMessage.json
            Error.errorWordingList[category] = wordingJson
            if ( callback != null && callback !== undefined ){
                callback(_thisRef)
            }
        });
    }

    isExist(category:string, json:any) : boolean {
        if ( !Error.errorWordingList[category] ){
            return false
        }
        if ( !Error.errorWordingList[category][json] ){
            return false
        }
        return true
    }

    add(category:string, json:any){
        Error.errorWordingList[category] = json
    }

    get(category:string, key:string) : any | null {
        //log.dot('Error', Error.errorWordingList)
        if ( !Error.errorWordingList[category] ){
            return null
        }
        return Error.errorWordingList[category][key]
    }


    getErrorMessage(category:string, errorMsgId:string, apiCode:string, errorCode:string){
		
        log.it('category',category);
        log.it('errorMsgId',errorMsgId);
        log.it('apiCode',apiCode);
        log.it('errorCode',errorCode);
        
        var errorMsgInPrompt = '';
        var errorCodePrompt = ''
        if( errorMsgId ){
            var theMsg = this.get(category,errorMsgId) ?? this.get(Error.CAT_COMMON,"system_busy")
            errorMsgInPrompt = wording.get(theMsg);
        }
        if( errorMsgInPrompt === ''){
            errorMsgInPrompt = errorMsgId
        }
        
        
        if(errorCode && apiCode){			
            if( API.error && API.error[errorCode]!= null){  //API.error from ARMS API json
                errorCodePrompt = "[" + apiCode + "/" + API.error[errorCode].code + "]";
            }
            else if ( API.error ){
                errorCodePrompt = '[' + apiCode  +'/' + errorCode  + ']'
            }
            else{
                errorCodePrompt = '[' + apiCode  +'/-1]';
            }				
        }
        else if ( errorCode && !apiCode ){
            errorCodePrompt = '(' + errorCode  +')';
        }
        
        if ( Config.promptErrorToConsole ){
            log.ot('ERROR', errorMsgInPrompt + '\n' + errorCodePrompt)
        }

        return errorMsgInPrompt + ' ' + errorCodePrompt
	
	}

}