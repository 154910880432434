
//import {ASession} from '../../common/ASession'
//import { Operator, Technology, Bandwidth, CustomerType } from './FbbEnum'
import * as log from '../../common/js/appLogger'
import { IModelUtil } from '../common/IModelUtil'
import { SpecialOfferGroup } from './SpecialOfferGroup'

export class SpecialOffer implements IModelUtil{

    protected marketingCode:string = ""
    protected ldCode:string = ""
    protected refCode:string = ""
    protected price:Number = 0

    protected nameEn:String = ""
    protected nameZh:String = ""
    protected iconPathEn:String = ""
    protected iconPathZh:String = ""

    // in detail prompt ui
    protected detailIconPathEn:String = ""
    protected detailIconPathZh:String = ""
    protected detailContentEn:String = ""
    protected detailContentZh:String = ""
    protected detailTncEn:String = ""
    protected detailTncZh:String = ""

    public getMarketingCode() : string {
        return this.marketingCode
    }

    public getLDCode() : string {
        return this.ldCode
    }
    
    parseApiResult(data: any): void {
        this.marketingCode = data.marketingCode ?? ""
        this.ldCode = data.ldCode ?? ""

        this.refCode = data.refCode ?? ""
        this.price = data.price ?? 0
        this.nameEn = (data['title'] ?? [])['en'] ?? ""
        this.nameZh = (data['title'] ?? [])['zh'] ?? ""

        // /img/offer_option/coupon.png is default image
        this.iconPathEn = (data['imagePath'] ?? "/img/offer_option/coupon.png")
        this.iconPathZh = (data['imagePath'] ?? "/img/offer_option/coupon.png")

        // in detail prompt ui
        this.detailIconPathEn = (data['detailImagePath'] ?? "/img/offer_option/coupon.png")
        this.detailIconPathZh = (data['detailImagePath'] ?? "/img/offer_option/coupon.png")

        this.detailContentEn = (data['content'] ?? [])['en'] ?? `<div class='a1-laop '>
        <div class='a1-laop-subtitle'>__SUBTITLE__</div>
        <div class='a1-laop-detail-content' >
            <ul class='pad-top20'>
                <li>集合TVB自家節目、亞洲人氣劇集及綜藝、最新日本動畫、電影及粵語片等</li>
                <li>放送超過50條電視頻道及32,000小時點播節目</li>
                <li>提供高達4K畫質的點播服務</li>
                <li>可跨屏幕同時睇，滿足全家的娛樂需要</li>
                <li>配合SmarTone光纖寬頻服務，享受順暢的家庭影視娛樂</li>
            </ul>
            <div class='a1-laop-subtitle pad-top30'>可供選擇組合</div>
            <ul class='pad-top20'>
                <li>基本組合</li>
                <li>TVB外購節目點播組合</li>
                <li>精選基本頻道組合</li>
            </ul>
        </div>
    </div>`
        this.detailContentZh = (data['content'] ?? [])['zh'] ?? `<div class='a1-laop '>
        <div class='a1-laop-subtitle'>__SUBTITLE__</div>
        <div class='a1-laop-detail-content' >
            <ul class='pad-top20'>
                <li>集合TVB自家節目、亞洲人氣劇集及綜藝、最新日本動畫、電影及粵語片等</li>
                <li>放送超過50條電視頻道及32,000小時點播節目</li>
                <li>提供高達4K畫質的點播服務</li>
                <li>可跨屏幕同時睇，滿足全家的娛樂需要</li>
                <li>配合SmarTone光纖寬頻服務，享受順暢的家庭影視娛樂</li>
            </ul>
            <div class='a1-laop-subtitle pad-top30'>可供選擇組合</div>
            <ul class='pad-top20'>
                <li>基本組合</li>
                <li>TVB外購節目點播組合</li>
                <li>精選基本頻道組合</li>
            </ul>
        </div>
    </div>`
        this.detailTncEn = (data['tnc'] ?? [])['en'] ?? ""
        this.detailTncZh = (data['tnc'] ?? [])['zh'] ?? ""

    }

    convertToJson() : any {
        return {
            marketingCode: this.marketingCode,
            ldCode: this.ldCode,
            
            refCode: this.refCode,
            price: this.price,
            nameEn: this.nameEn,
            nameZh: this.nameZh,
            
            iconPathEn: this.iconPathEn,
            iconPathZh: this.iconPathZh,
        }
    }
    convertFromJson(json: any): void {
        
        this.marketingCode = json.marketingCode ?? ""
        this.ldCode = json.ldCode ?? ""

        this.refCode = json.refCode ?? ""
        this.price = json.price ?? 0
        this.nameEn = json.nameEn ?? ""
        this.nameZh = json.nameZh ?? ""
        
        this.iconPathEn = json.iconPathEn ?? ""
        this.iconPathZh = json.iconPathZh ?? ""

    }

    public getNameEn() : string{
        return this.nameEn + ''
    }

    public getNameZh() : string{
        return this.nameZh + ''
    }

    public getIconPathEn() : string{
        return this.iconPathEn + ''
    }

    public getIconPathZh() : string{
        return this.iconPathZh + ''
    }

    public getDetailContentEn() : string{
        return this.detailContentEn + ''
    }

    public getDetailContentZh() : string{
        return this.detailContentZh + ''
    }

    public getDetailIconPathEn() : string{
        return this.detailIconPathEn + ''
    }

    public getDetailIconPathZh() : string{
        return this.detailIconPathZh + ''
    }

    public getDetailTncEn() : string{
        return this.detailTncEn + ''
    }

    public getDetailTncZh() : string{
        return this.detailTncZh + ''
    }

    static parseMandatoryApiList(allOffers:any, mandaList:any) : Array<SpecialOffer> {
        //log.ot('allOffers', allOffers)
        //log.ot('mandaList', mandaList)

        var list:Array<SpecialOffer> = []
        for( var aJson of mandaList ){
            //log.i('manda: '+ aJson['marketingCode'])
            for( var offer of allOffers ){
                //log.i(aJson['marketingCode'] + ' : ' + offer['marketingCode'])
                if ( (aJson['marketingCode'] ?? '_') === (offer['marketingCode'] ?? '-') ){
                    //log.ot('match!', offer)
                    var offerObj = new SpecialOffer()
                    offerObj.parseApiResult(offer)
                    list.push(offerObj)
                }
            }
        }

        //log.ot('manda list object', list)
        return list
    }

    //e.g. choose 1 of 3.
    static parseOptionApiList(allOffers:any, optionList:any) : Array<SpecialOfferGroup> {
        //log.ot('allOffers', allOffers)
        //log.ot('optionList', optionList)

        var list:Array<SpecialOfferGroup> = []
        var subList:Array<SpecialOffer> = []

        for( var aRow of optionList ){
            var maxSelectCount = aRow['maxSelectCount'] ?? 1
            subList = []
            for( var anOption of aRow['list'] ){
                //log.i('manda: '+ aJson['marketingCode'])
                for( var offer of allOffers ){
                    //log.i(anOption['marketingCode'] + ' : ' + offer['marketingCode'])
                    if ( (anOption['marketingCode'] ?? '_') === (offer['marketingCode'] ?? '-') ){
                        //log.ot('match!', offer)
                        var offerObj = new SpecialOffer()
                        offerObj.parseApiResult(offer)
                        subList.push(offerObj)
                    }
                }
            }            
            var g = new SpecialOfferGroup()
            g.setSpecialOfferList(subList)
            g.setMaxSelectCount(maxSelectCount)
            list.push(g)
        }

        //log.ot('optional list object', list)

        return list
    }


}