export enum CoverageType {
    Residential = 'R',
    Commercial = 'C',
}

export enum BuildingType {
    Public = 'PUBLIC',
    Private = 'PRIVATE',
    Village = 'VILLAGE',
}

export enum Technology {
    DOS = 'DOS',
    ME = 'ME',
    GP = 'GP'
}

export enum Operator {
    hkbn = 'hkbn',
    hgc = 'hgc',
    icable = 'icable',
}

export enum CustomerType {
    SMC = 'SMC',
    MASS = 'MASS',
    //corpWeb = 'corpWeb',
}

export enum Bandwidth {
    b100 = '100',
    b200 = '200',
    b500 = '500',
    b1000 = '1000',
    b2000 = '2000',
}

export enum AddOnType{
    SINGLE = '1',   //Just choose it, no options
    CHOOSE1 = '2',  //norton case, choose one of the plan
    SUB_ADDON = '3' //hometel case, choose base hometel, then add additional VAS like call forwarding
}

export enum CurrentISP{
    PCCW = 'PCCW',
    HKBN = 'HKBN',
    THREE_HK = '3HK',
    ICABLE = 'ICABLE',
    CMHK = 'CMHK',
    NA = 'NA',
}


export enum PlanTagValue{
    REC = "REC",
    b100 = "100M",
    b200 = "200M",
    b500 = "500M",
    b1000 = "1000M",
    b2000 = "2000M",
}

export enum OfflinePaymentType{
    CASH = "cash",
    CREDITCARD_AUTO = "creditcard",
    BACKACC_AUTO = "bankaccount",
}