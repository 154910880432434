/* eslint-disable */
import {ASession} from '../../common/ASession'

export class Installation extends ASession{

    protected sessionKey:string = "Installation"


    protected installDate:string | null = null

    protected installTime:any = null

    protected deferTime:string | null = null

    protected contactPerson:string | null = null

    protected contactPhone:string | null = null

    protected contactSameAsPersonalInfo:string = 'Y'


//=================================================================================

    constructor(){
        super()
        this.setData(this.getDefaultData())
    }

    
    protected getDefaultData() : any{
        return {
            installDate: null,
            installTime: {},
            deferTime: null,
            contactPerson: null,
            contactPhone: null,
            contactSameAsPersonalInfo:'Y',
        }
    }

    protected getData() : any {
        //throw new Error("Method not implemented.")
        return {
            installDate:this.installDate ?? null,
            installTime:this.installTime ?? {},
            deferTime:this.deferTime ?? null,
            contactPerson:this.contactPerson ?? null,
            contactPhone:this.contactPhone ?? null,
            contactSameAsPersonalInfo:this.contactSameAsPersonalInfo ?? 'Y'
        }

    }
    protected setData(data: any) {
        
        this.installDate = data.installDate
        this.installTime = data.installTime
        this.deferTime = data.deferTime
        this.contactPerson = data.contactPerson
        this.contactPhone = data.contactPhone
        this.contactSameAsPersonalInfo = data.contactSameAsPersonalInfo

        //don't call save() here.
    }
    
    public setInstallDate(date:string | null){
        this.installDate = date
    }

    public setInstallTime(time:any | null){
        this.installTime = time
    }

    public setDeferTime(date:string | null){
        this.deferTime = date
    }

    public setContactPerson(p:string | null){
        this.contactPerson = p
    }

    public setContactPhone(p:string | null){
        this.contactPhone = p
    }

    public replaceData(ins:Installation){
        this.setInstallDate(ins.installDate)
        this.setInstallTime(ins.installTime)
        this.setDeferTime(ins.deferTime)

        this.setContactPerson(ins.contactPerson)
        this.setContactPhone(ins.contactPhone)
        this.setContactSameAsPersonalInfo(ins.isContactSameAsPersonalInfo())
    }

    public getInstallDate() : string | null{
        return this.installDate
    }

    public getInstallTime() : any | null{
        return this.installTime
    }

    public getDeferTime() : any | null{
        return this.deferTime
    }

    public getContactPerson() : string | null{
        return this.contactPerson
    }

    public getContactPhone() : string | null{
        return this.contactPhone
    }

    public setContactSameAsPersonalInfo(isSame:boolean){
        this.contactSameAsPersonalInfo = isSame ? 'Y' : 'N'
    }

    public isContactSameAsPersonalInfo() : boolean {
        return this.contactSameAsPersonalInfo == 'Y'
    }




    //=================================================================================




}