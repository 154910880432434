
//import * as log from '../common/js/appLogger'
import {ASession} from '../../common/ASession'
import {Coverage} from '../Coverage'
import { Operator } from '../FbbEnum'
//import * as log from '../../../common/js/appLogger'


export class AllCoverage extends ASession {

    protected sessionKey:string = "AllCoverage"

    protected penetrationRate:number = 0

    protected hkbn:Coverage | null = null
    protected hgc:Coverage | null = null
    protected icable:Coverage | null = null


//=================================================================================

    
    protected getDefaultData() : any{
        return {
            penetrationRate:0,
            hkbn:null,
            hgc:null,
            icable:null,
        }
    }

    protected getData() : any {
        return {
            penetrationRate: this.penetrationRate,
            hkbn:this.hkbn?.convertToJson(),
            hgc:this.hgc?.convertToJson(),
            icable:this.icable?.convertToJson(),
        }

    }
    protected setData(data: any) {
        
        this.penetrationRate = data.penetrationRate
        this.hkbn = new Coverage(Operator.hkbn)
        this.hkbn.convertFromJson(data.hkbn)

        this.hgc = new Coverage(Operator.hgc)
        this.hgc.convertFromJson(data.hgc)
        this.icable = new Coverage(Operator.icable)
        this.icable.convertFromJson(data.icable)


        //don't call save() here.
    }

    protected haveCoverage() : boolean {
        //log.d('haveCoverage', this.hkbn?.haveCoverage())
        return (this.hkbn?.haveCoverage() ?? false) || 
                (this.hgc?.haveCoverage() ?? false) || 
                (this.icable?.haveCoverage() ?? false)
    }

    protected havePort() : boolean {
        //log.d('havePort', this.hkbn?.havePort())
        return (this.hkbn?.havePort() ?? false) || 
                (this.hgc?.havePort() ?? false) || 
                (this.icable?.havePort() ?? false)
    }

    protected haveCoverageAndPort() : boolean {
        var hkbn = (this.hkbn?.haveCoverage() ?? false) && (this.hkbn?.havePort() ?? false)
        var hgc = (this.hgc?.haveCoverage() ?? false) && (this.hgc?.havePort() ?? false)
        var icable = (this.icable?.haveCoverage() ?? false) && (this.icable?.havePort() ?? false)

        return hkbn || hgc || icable
    }

    public getPenetrationRate() : number {
        return this.penetrationRate + 0
    }

    public setPenetrationRate(rate:number) : void {
        this.penetrationRate = rate
    }

    protected setCoverage(operator:Operator, apiResult:any){
        var c = new Coverage(operator)
        c.parseApiResult(apiResult)
        //log.it('AllCoverage', 'operator: ' + (operator === Operator.hkbn))
        if ( operator === Operator.hkbn ){
            this.hkbn = c
        }
        else if ( operator === Operator.hgc ){
            this.hgc = c
        }
        else if ( operator === Operator.icable ){
            this.icable = c
        }
    }

    public getHkbnCoverage() : Coverage{
        return (this.hkbn ?? new Coverage(Operator.hkbn) )
    }

    public getHGCCoverage() : Coverage{
        return (this.hgc ?? new Coverage(Operator.hgc) )
    }

    public getICableCoverage() : Coverage{
        return (this.icable ?? new Coverage(Operator.icable) )
    }

    //=================================================================================




}