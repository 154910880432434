import { AddOn } from './AddOn'
import { AddOnType } from '../FbbEnum'
import * as log from '../../../common/js/appLogger'

//!!!!! metehod createGAItem depends on this list. Please check against.
export const KKBoxOptionList = [    
    {
        "order": 1,
        "vasCode": "FLKK2",
        "marketCode": "MWT54",
        "ldCode": "LDA12M49",
        "key": "kkbox-12-contract",
        "fee": 44,
        "gaClickEvent": "kkbox_12m_contract",
        "title": {
            "en": "KKBOX (12-month contract)",
            "zh": "KKBOX (12個月合約)"
        },
        "emailContent":{
            "en": "KKBOX Monthly Subscription (12-month contract) – HK$44 per month <br/>(KKBOX activation SMS will be sent to your mobile number when the fibre broadband service has activated) ",
            "zh": "KKBOX – 月費計劃 (12個月合約) – 每月$44 <br/>(光纖寬頻服務生效後，KKBOX啟動服務短訊將發送到你的流動電話號碼)"
        }
    },
    {
        "order": 2,
        "vasCode": "FLKK1",
        "marketCode": "",
        "ldCode": "",
        "key": "kkbox-no-contract",
        "fee": 49,
        "gaClickEvent": "kkbox_no_contract",
        "title": {
            "en": "KKBOX (No contract)",
            "zh": "KKBOX (無合約)"
        },
        "emailContent":{
            "en": "KKBOX Monthly Subscription (No contract) – HK$49 per month <br/>(KKBOX activation SMS will be sent to your mobile number when the fibre broadband service has activated) ",
            "zh": "KKBOX – 月費計劃 (無合約) – 每月 $49 <br/>(光纖寬頻服務生效後，KKBOX啟動服務短訊將發送到你的流動電話號碼)"
        }
    },
    {
        "order": 3,
        "vasCode": "FLKKF",
        "marketCode": "",
        "ldCode": "",
        "key": "kkbox-no-contract-3-account",
        "fee": 79,
        "gaClickEvent": "kkbox_3_person_family_plan",
        "title": {
            "en": "KKBOX 3-person Family Plan (No contract)",
            "zh": "KKBOX 家 庭 共 享 計 劃 (3 人 共 享) (無 合 約)"
        },
        "emailContent":{
            "en": "KKBOX 3-person Family Plan (No contract) – HK$79 per month <br/>(KKBOX activation SMS will be sent to your mobile number when the fibre broadband service has activated) ",
            "zh": "KKBOX 家庭共享計劃 (3人共享)(無合約) – 每月$79 <br/>(光纖寬頻服務生效後，KKBOX啟動服務短訊將發送到你的流動電話號碼)"
        }
    }
]

export enum AddOnKKBoxPlan{
    NONE  = -1,
    Plan1 = 0,
    Plan2 = 1,
    Plan3 = 2,
}

export class AddOnKKBox extends AddOn{
    
    protected selectedPlanList:Array<any> = []
    protected maxPlanSelected:number = 1;
    protected planIndex:AddOnKKBoxPlan = AddOnKKBoxPlan.NONE
    protected plan:any = null;

    constructor(){
        super()
        this.key = 'AddOnKKBox'
        
        this.boxIconEn = ''
        this.boxIconZh = ''
        this.boxNameEn = 'KK Box'
        this.boxNameZh = 'KK Box'
        this.boxAmount = 44
        this.hasRedCounter = false
        /*
        this.landingIcon['en'] = '/img/addon/router.png'
        this.landingIcon['zh'] = '/img/addon/router.png'

        this.landingTitle['en'] = ''
        this.landingTitle['zh'] = ''
        this.landingDesc1['en'] = ''
        this.landingDesc1['zh'] = ''
        this.landingDesc2['en'] = ''
        this.landingDesc2['zh'] = ''
        */
        this.type = AddOnType.CHOOSE1
        
    }

    public isAddOnSelected() : boolean{
        return this.planIndex !== AddOnKKBoxPlan.NONE
    }

    public setPlanList(planIndex:AddOnKKBoxPlan){
        this.planIndex = planIndex
        if(this.selectedPlanList.length >= this.maxPlanSelected){
            this.selectedPlanList.pop()
        }
        this.setPlan(KKBoxOptionList[planIndex])
        this.selectedPlanList.push(this.plan)     

        log.ot('setPlanList', this.plan)
        
    }
    public setDefaultPlan(){
        this.setPlanList(AddOnKKBoxPlan.Plan1)
        this.setSelectedCount(1)
    }

    public getPlanList() : Array<any> {
        return this.selectedPlanList;
    }
    
    public unselectPlan(){
        this.planIndex = AddOnKKBoxPlan.NONE
        this.selectedPlanList = []
        this.plan = null;
    }

    public setPlan(plan:any){
        this.plan = plan;
    }
    public getPlan() : any {
        return this.plan
    }
    public getPlanByIndex(planIndex: number) : any {
        if ( planIndex === AddOnKKBoxPlan.NONE ){
            return null;
        }
        else { 
            return AddOnKKBoxPlan[planIndex]
        }  
    }
    public getPlanIndex() : number {
        //return this.plan
        return this.planIndex;     
    }

    public getPlanPrice() : number {

        //log.ot('kkbox getPlanPrice', this)

        if ( this.planIndex === AddOnKKBoxPlan.NONE ){
            return 0;
        }
        else { 
            return (this.plan ?? {}).fee ?? 0
        }        
    }

    public getPlanVasCode() : string {
        if ( this.planIndex === AddOnKKBoxPlan.NONE){
            return ""
        }
        else{
            return this.plan.vasCode
        }       
    }

    public getPlanMktCode() : string {
        if ( this.planIndex === AddOnKKBoxPlan.NONE ){
            return ""
        }
        else{
            return this.plan.marketCode
        }
    }

    public getPlanLdCode() : string {
        if ( this.planIndex === AddOnKKBoxPlan.NONE ){
            return ""
        }
        else{
            return this.plan.ldCode
        }
    }

    public getOptionEmailContent(lang:string) : any{
        let emailContent = []
        if ( this.planIndex === AddOnKKBoxPlan.NONE ){}
        else { 
            emailContent.push(this.plan.emailContent[lang])
        }  
        return emailContent
    }

    /*
    {
      item_id: "fbb-addon-kkbox",
      item_name: "KKBOX (無合約)",
      currency: "HKD",
      item_brand: "KKBOX",
      item_category: "Services",
      item_list_id: "fbb",
      item_list_name: "FBB Portal",
      price: 49.00,
      quantity: 1
    }
    */
    //price handling reference OrderBox.
    public createGAItem(lang: string) : any{

        var selected = this.getPlanList()[0]
        //console.log('selected', selected)
        if ( selected == null || selected.length == 0 ){
            return {}
        }
        var main = {
            "item_id": "fbb-addon-kkbox",
            "item_name": selected['title'][lang],
            "currency": "HKD",
            "item_brand": "KKBOX",
            "item_category": "Services",
            "item_list_id": "fbb",
            "item_list_name": "FBB Portal",
            "price": selected['fee'],
            "quantity": 1,
        }
        return main
    }

}