/* eslint-disable */

//import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';

import 'babel-polyfill'
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';  //React Loadable
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
import {HashRouter} from "react-router-dom"
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
unregister();

smoothscroll.polyfill();
if(!window.ga){
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');    
}



ReactDOM.render(
    <HashRouter >
        <App />
    </HashRouter>, 
document.getElementById('root'));

//registerServiceWorker();
