//import React from 'react';
import {AppStatus} from "./AppStatus"
import {Error} from "../model/common/Error"
import * as log from '../common/js/appLogger'
import {Config} from "./Config"
import * as util from '../common/js/util'

//put items that the project templates should contain.
//do not put project specific items here.
export class AppData {
    
    static status:AppStatus
    static error:Error
    
    static urlParams:any = {} //not save to session.

    //add a class for storing page logic that need to save to session.
    //e.g. sorry page's reason

    static init(){
        log.dt('AppData', 'init')
        AppData.status = new AppStatus()
        AppData.error = new Error() //lazy load approach
    }

    static load(){
        AppData.init()
        AppData.status.load()
    }


    static isRBD_DS() : boolean {
        var sc = AppData.urlParams.sc ?? ''
        var msd = AppData.urlParams.msd ?? ''
        return sc.trim().length > 0 && msd.trim().length > 0
    }

}