
import * as log from '../../common/js/appLogger'
//import {ISessionHandler} from './ISessionHandler'

//A: Abstract
export abstract class ASession {

    protected abstract sessionKey:string    //name that stored to sessionStorage
    
    //data returned will be saved to sessionStorage. Called at save()
    protected abstract getData() : any
    
    //data passed in will be loaded into the object variable. Called at load()
    protected abstract setData(data:any) : any  
    
    protected abstract getDefaultData() : any

    //run the changes function, then save.
    public saveChanges(changes:any){
        changes(this)
        this.save()
    }

    //save current data to session
    public save(){
        var infoString = JSON.stringify(this.getData())
        this.logi('save session')   // + ', ' + infoString
        //this.logi('save session , ' + infoString)   // 
        sessionStorage.setItem(this.sessionKey, infoString)
    }

    //load session data to object
    public load(){
        var infoString = sessionStorage.getItem(this.sessionKey)
        this.logi('load session')   // + ', ' + infoString
        //this.logi('load session , ' + infoString)   // 
        this.setData(infoString ? JSON.parse(infoString) : {})
    }

    //clean object to default data state, then save to session
    public cleanStorage(){
        this.setData(this.getDefaultData())
        this.save()
        this.logi('cleanStorage')
    }

    public clear(){
        this.setData(this.getDefaultData())
    }

    //shortcut logging method, use sessionKey as tag
    protected logi(obj:any){
        log.dt(this.sessionKey, obj)
    }

}
