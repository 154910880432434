import { AddOn } from './AddOn'
//import * as wording from '../../../common/js/wording'
import { AddOnType, Operator } from '../FbbEnum'
import * as log from '../../../common/js/appLogger'


/*
Pls submit “Z,1” for equipList plus below values (like Norton):

WiFi路由器	VAS Code	標準月費	Mktg code	收費總額	合約期(月)	合約代號	T&C
Asus RT-AC68U	FBRM1	$40	MVZ08	$960	24	LDJ24M40	N003PB
*/
//!!!!! metehod createGAItem depends on this list. Please check against.
export class AddOnRouter extends AddOn{

    static readonly codeInfo = [
        /*
        {
            equipList: 'Z,1',
            vasCode:'FBRM1',
            marketingCode: 'MVZ08',
            ldCode:'LDJ24M40',
            contract:24,
            price:40,
            operator: Operator.hkbn
        },
        {
            equipList: 'Z,1',
            vasCode:'FBRM2',
            marketingCode: 'MXN13',
            ldCode:'LDJ12M80',
            contract:12,
            price:80,
            operator: Operator.hkbn
        },*/
        {
            equipList: 'Y,1',
            vasCode:'FBRT8',
            marketingCode: '',
            ldCode:'',
            contract:0,
            price:880,
            boxPrice:0,
            submitPrice:false,
            operator: Operator.hkbn
        },
        {
            equipList: 'GA,1',
            vasCode:'FBRT8',
            marketingCode: '',
            ldCode:'',
            contract:0,
            price:880,
            boxPrice:0,
            submitPrice:false,
            operator: Operator.hgc
        },
        {
            equipList: 'CA,1',
            vasCode:'FBRT8',
            marketingCode: '',
            ldCode:'',
            contract:0,
            price:880,
            boxPrice:0,
            submitPrice:false,
            operator: Operator.icable
        }
    ]

    protected emailContent = [
        /*
        {
            "contract":24,
            "operator": Operator.hkbn,
            "en": "WiFi Router (24 month contract) – HK$40 per month",
            "zh": "WiFi 路由器 – 月費計劃 (24個月合約) – 每月$40"
        },
        {
            "contract":12,
            "operator": Operator.hkbn,
            "en": "WiFi Router (12 month contract) – HK$80 per month",
            "zh": "WiFi 路由器 – 月費計劃 (12個月合約) – 每月$80"
        },*/
        {
            "contract":0,
            "operator": Operator.icable,
            "en": "WiFi Router - HK$880 (To be charged in the first bill)",
            "zh": "WiFi 路由器 – $880 (費用將於首月賬單收取)"
        },
        {
            "contract":0,
            "operator": Operator.hkbn,
            "en": "WiFi Router - HK$880 (To be charged in the first bill)",
            "zh": "WiFi 路由器 – $880 (費用將於首月賬單收取)"
        },{
            "contract":0,
            "operator": Operator.hgc,
            "en": "WiFi Router - HK$880 (To be charged in the first bill)",
            "zh": "WiFi 路由器 – $880 (費用將於首月賬單收取)"
        }
    ]
        

    constructor(){
        super()
        this.key = 'AddOnRouter'
        
        this.boxIconEn = ''
        this.boxIconZh = ''
        this.boxNameEn = 'Router'
        this.boxNameZh = 'Router'
        this.boxAmount = 40
        this.hasRedCounter = false
        /*
        this.landingIcon['en'] = '/img/addon/router.png'
        this.landingIcon['zh'] = '/img/addon/router.png'

        this.landingTitle['en'] = ''
        this.landingTitle['zh'] = ''
        this.landingDesc1['en'] = ''
        this.landingDesc1['zh'] = ''
        this.landingDesc2['en'] = ''
        this.landingDesc2['zh'] = ''
        */
        this.type = AddOnType.SINGLE
        
    }

    public isAddOnSelected() : boolean{
        return this.boxCount > 0
    }

    //=================================================================================
    /** no overwrite AddOn one 
    public getData() : any {
        //var data = super.getData()
        return data
    }
    public parse(data: any) {
        //super.parse(data)
    }
    */
    //=================================================================================


    public getOptionEmailContent(lang:string, contractMonth:Number, op: Operator) : any{
        log.ot('router getOptionEmailContent', this.emailContent)
        log.it('router getOptionEmailContent2', this.isAddOnSelected() + ', ' + lang + ',' + contractMonth + ',' + op)
        /*
        var m = contractMonth
        if ( m != 24 && m != 12 ){
            m = 24
        }*/
        let emailContent = []
        if ( this.isAddOnSelected() ){
            for(var ec of this.emailContent){
                //log.it('router getOptionEmailContent3', ec.contract + ',' + m)
                if ( ec.operator === op ){ //op !== Operator.hkbn &&
                    //log.it('router getOptionEmailContent4', lang)
                    emailContent.push(ec[lang])
                }
                // else if ( op === Operator.hkbn && ec.operator === op && ec.contract === m ){
                //     //log.it('router getOptionEmailContent4', lang)
                //     emailContent.push(ec[lang])
                // }
            }
        }
        return emailContent
    }

    /*
    public getOptionEmailContent(lang:string) : any{
        let emailContent = []
        if ( this.isAddOnSelected() ){
            emailContent.push(this.emailContent[lang])
        }
        return emailContent
    }*/


    public getBoxAmount(contractMonth:String | null, op: Operator) : Number{
        
        //alert(contractMonth)
        for(var ec of AddOnRouter.codeInfo){
            if ( ec.operator === op ){ //op !== Operator.hkbn && 
                this.boxAmount = ec.boxPrice
                return ec.boxPrice
            }
        }
        return this.boxAmount
    }

    //override
    public getAmount(contractMonth:String | null, op: Operator) : Number{
        var m = contractMonth
        if ( m != 24 && m != 12 ){
            m = 24
        }
        m = parseInt(contractMonth)
        //alert(contractMonth)
        for(var ec of AddOnRouter.codeInfo){
            if ( ec.operator === op ){ //op !== Operator.hkbn && 
                this.boxAmount = ec.price
                return ec.price
            }
            // else if ( op === Operator.hkbn && ec.operator === op && ec.contract == m ){    //string vs number
            //     this.boxAmount = ec.price
            //     return ec.price
            // }
        }
        return this.boxAmount
    }

    public getContractMonth(tech:String | null, buildingType: String | null, 
                    contractMonth: String | null, op:Operator) : Number{

        for(var aJson of AddOnRouter.codeInfo){
            if ( aJson.operator === op ){
                if ( aJson.contract === 0 ){
                    return 0
                }
            }
        }

        if ( contractMonth == null ){
            return 0
        }

        try{
            var m = parseInt(contractMonth)
            if ( m == 30 ){    //28-Aug-2020: 30 month plan use 24 month contract router.
                m = 24 
            }
            return m
        }
        catch(e){
            return 0
        }
    }

    /*
    {
      item_id: "fbb-addon-kkbox",
      item_name: "KKBOX (無合約)",
      currency: "HKD",
      item_brand: "KKBOX",
      item_category: "Services",
      item_list_id: "fbb",
      item_list_name: "FBB Portal",
      price: 49.00,
      quantity: 1
    }
    */
    //price handling reference OrderBox.
    public createGAItem(lang: string, contractPeriod: string, operator:Operator) : any{
        var main = {
            "item_id": "fbb-addon-router",
            "item_name": this.getName(lang),
            "currency": "HKD",
            "item_brand": "ROUTER",
            "item_category": "Services",
            "item_list_id": "fbb",
            "item_list_name": "FBB Portal",
            "price": this.getBoxAmount(contractPeriod, operator),
            "quantity": 1,
        }
        return main
    }


}