//import * as log from '../common/js/appLogger'
import * as util from '../common/js/util'
import {ASession} from '../model/common/ASession'
import {Config} from "./Config"
import * as log from '../common/js/appLogger'

export class AppStatus extends ASession {

    protected sessionKey: string   = "AppStatus"  

    protected debugMode:boolean = false
    protected app?:string
    protected appVersion?:string

    protected errorPromptData: any

    //URL param is the first control.
    //Then getPlan api's return get extra config.
    //Since need to retrieve via api, need to save to session.
    protected offlineConfig:any = {
        isOffline:false, //This could be set to false if getPlan api return non offline stuff.
        onlineChannel:'', //return at get plan api 
        needPay:'', //Retrieved from getPlan api. 
    }

    constructor(){
        super()
        //log.dt('AppStatus', 'constructor')
        this.setData(this.getDefaultData())
    }

    protected getDefaultData() : any{
        //log.dt('AppStatus', 'getDefaultData')
        return {
            debugMode: false,
            app: null,
            appVersion: null,
            errorPromptData: {
                errorPromptShow: false,
                errorPromptDesc: '',
                errorPromptTitle: '',
                errorPromptCloseBtnText: {}
            },

            offlineConfig: {
                isOffline:false,
                needPay:'',
                onlineChannel:'',
            }
        }
    }

    protected getData() : any {
        //log.dt('AppStatus', 'getData')
        return {
            debugMode: this.debugMode,
            app: this.app,
            appVersion: this.appVersion,
            errorPromptData: this.errorPromptData,
            offlineConfig: this.offlineConfig ?? {},
        }
    }
    protected setData(data: any) {
        this.debugMode = data.debugMode ?? false
        this.app = data.app
        this.appVersion = data.appVersion

        var promptData = data.errorPromptData ?? {}
        this.errorPromptData = {
            errorPromptShow: promptData.errorPromptShow ?? false,
            errorPromptDesc: promptData.errorPromptDesc ?? '',
            errorPromptTitle: promptData.errorPromptTitle ?? '',
            errorPromptCloseBtnText: promptData.errorPromptCloseBtnText ?? {}
        }

        this.offlineConfig = data.offlineConfig
        if ( !this.offlineConfig ){
            this.offlineConfig = {
                isOffline:false,
                needPay:'',
                onlineChannel:'',
            }
        }

        //log.dot('AppStatus setData-data', data)
        log.dot('AppStatus setData', this.offlineConfig)
        //dont add save() here.
    }

    //override
    public load(){
        super.load()

        var params:any = util.getUrlVars(null)

        //priority: URL param > session
		// eslint-disable-next-line eqeqeq
		var paramMode = params['debug'] && params['debug'] == 'Y'
		if( paramMode ){
			this.debugMode = paramMode
        }
        var app:string = params['app']
        if ( app ){
            this.app = app
        }

        var appVersion:string = params['appVersion']
        if ( appVersion ){
            this.appVersion = appVersion
        }


        this.resetOfflineConfig(this.offlineConfig.onlineChannel, this.offlineConfig.needPay)

        this.logi('override by URL param')
        log.dot('AppStatus after load', this.offlineConfig)
        this.save()
    }

    public cleanOfflineStatus(){
        log.dt('AppStatus', 'cleanOfflineStatus')
        this.offlineConfig.onlineChannel = ''
        this.offlineConfig.needPay = ''        
    }

    //suppose getPlan is re-called, re-calculate the offline status.
    public resetOfflineConfig(onlineChannel:string, offlinePay: string){
        
        this.offlineConfig.onlineChannel = onlineChannel ?? ''
        this.offlineConfig.needPay = offlinePay ?? ''

        if ( !Config.supportOffline ){
            log.it('AppStatus', 'Global-flag online mode')
            this.offlineConfig.isOffline = false
        }
        else{
            var paramB4Hash = util.getParamBeforeHash(false)
            if ( paramB4Hash.off === 'Y' && (util.isLocal() || window.ipOffline == 'Y') ){
                log.it('AppStatus', 'param + ip offline mode')
                this.offlineConfig.isOffline = true
            }
            else{
                log.it('AppStatus', 'param online mode')
                this.offlineConfig.isOffline = false
            }

            if ( this.offlineConfig.onlineChannel == 'Y' || this.offlineConfig.onlineChannel == 'true' ){
                log.it('AppStatus', 'onlineChannel, reset online mode')
                this.offlineConfig.isOffline = false
            }
        }
        
        log.dot('AppStatus after resetOfflineConfig', this.offlineConfig)

    }

    public isDebugMode() : boolean{
        return this.debugMode ? true : false
    }

    public getAppVersion() : string | null {
        return (this.appVersion ? this.appVersion + '' : null)
    }

    public getErrorPromptInfo() : any {
        return Object.assign({}, this.errorPromptData);
    }

    public setErrorPromptInfo(info: any){
        this.errorPromptData = {
            errorPromptShow: info.errorPromptShow ?? false,
            errorPromptDesc: info.errorPromptDesc ?? '',
            errorPromptTitle: info.errorPromptTitle ?? '',
            errorPromptCloseBtnText: info.errorPromptCloseBtnText ?? {}
        }
        this.save()
    }

    public resetOfflineMode(){
        log.it('AppStatus', '----- Reset offline mode - check onlineChannel -----')
        this.offlineConfig.isOffline = false
    }

    public isOfflineMode() : boolean{
        return this.offlineConfig.isOffline
    }

    public isOfflineNeedPay() :boolean {
        return this.offlineConfig.needPay == 'Y'
    }
    
    public setOfflineNeedPay(needPay:boolean){
        this.offlineConfig.needPay = needPay
    }

    public setOnlineChannel(ch:String){
        this.offlineConfig.onlineChannel = ch
    }

    public printOfflineConfig(ref:String){
        log.dot('AppStatus offlineConfig ['+ref +']', this.offlineConfig)
    }

}