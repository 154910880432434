/* eslint-disable */
import compareVersions from 'compare-versions';
import * as log from './appLogger'
import { AppData } from '../../data/AppData';
import { Config } from '../../data/Config';


//return params array. pass null url will check location.href.
export function getUrlVars(url:string | null) {
  url = url || window.location.href;
  var vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi,  

  function(m,key,value) {
    vars[key] = value;
  });
  return vars;
}



export function isLocal() : boolean {
  return (/10.0.0.1|localhost|127.0.0/).test(window.location.hostname);
}

export function isDev() : boolean {
  return (/34.192.230.83|34.230.99.141|dev.vaka.com/).test(window.location.hostname);
}

export function isStg() : boolean {
  return (Config.SERVER_CHECK_STAGING).test(window.location.hostname);
}

export function isPreProd() : boolean{
  return (Config.SERVER_CHECK_PREPROD).test(window.location.href);
}

export function isProd() : boolean {
  return (Config.SERVER_CHECK_PROD).test(window.location.href); //!isLocal() && !isDev() && !isStg();
}

//check UA instead.
export function isAndroid() : boolean{
  //return false //window["getMobileOperatingSystem"]() == "android"
  throw new Error("Method not implemented.")
}

export function isIOS() : boolean {
  var ua = navigator.userAgent,
  iOS = /iPad|iPhone|iPod/.test(ua)
  if( iOS ){
    return true
  }
  return false
}

export function isSafari() : boolean{
  var ua = navigator.userAgent.toLowerCase(); 
  log.it('ua', ua)
  if (ua.indexOf('safari') != -1) { 
    if (ua.indexOf('chrome') > -1) {
      return false
    } 
    else {
      return true
    }
  }
  return false
}

export function isUnderIOS13() : boolean {
  var ua = navigator.userAgent,
  iOS = /iPad|iPhone|iPod/.test(ua),
  //iOS11 = /OS 11_/.test(ua),
  underiOS13 = (/OS 9_|OS 10_|OS 11_|OS 12_/.test(ua));

  if(iOS && underiOS13){
    return true
  }
  return false
}


export function showMobileStyleVas() : boolean {
  var winWidth = window.innerWidth
  return winWidth <= Config.VAS_MOBILE_STYLE_MAX_WIDTH
}

export function isMobile() : boolean {
  throw new Error("Method not implemented.")
}

export function safeJson(json:any, key:string) : any {
  return json[key] ?? []
}

export function getDevice() {
	if (isMobile()) {
		if (window.screen.width < 768) {
			return 'mobile';
		} else {
			return 'tablet';
		}
	} else {
		return 'laptop';
	}
}


export function getParamBeforeHash(checkEnd:boolean){
    //Handle iFrame for non-local one
    var url = location.href

    //log.it('getFbbQueryParams 1', url)
    var hashInx = url.indexOf('#')
    if ( hashInx == -1 && !checkEnd ){
      return {}
    }
    if ( checkEnd ){
      hashInx = url.length - 1
    }
    url = url.substring(0, hashInx)
    //log.it('getFbbQueryParams 2', url)

    var vars = {};
    var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi,    
            function(m,key,value) {
            vars[key] = value;
          });
    //log.ot('getFbbQueryParams2', vars)
    return vars
}

// only add when call API:
export function add852ToMsisdn(tempMsisdn: string){
  let returnMsisdn = ""
  let regex = /^[0-9]{8}$/g
  if(regex.test(tempMsisdn)){
    returnMsisdn = '852' + tempMsisdn
  }else{
    returnMsisdn = tempMsisdn
  }
  return returnMsisdn;
}

export function remove852FromMsisdn(tempMsisdn : string){
  let returnMsisdn = ""
  let regex = /^[8][5][2][0-9]{8}/g
  if(regex.test(tempMsisdn)){
    returnMsisdn = tempMsisdn.substring(3,tempMsisdn.length);
  }else{
    returnMsisdn = tempMsisdn
  }
  return returnMsisdn;
}

/**
 * @param {
 * } targetVersion : 1.1.23   1.3.0.8
 * return: -1 / 0 / 1
 * mean: appVersion smaller targetVersion / equal / appVersion greater targetVersion
 */
export function compareAppVersionToTarget(targetVersion : string){
  
  var appVersion = AppData.status.getAppVersion()
  if( !appVersion ){
    return -1;
  }
  else if( appVersion == targetVersion){
    return 0;
  }
  else{
    let compare = compareVersions(appVersion, targetVersion); // targetVersion
    log.it('util',"current verion no. = " + appVersion + " \n ---- target verion no. = " + targetVersion + "\n ---- => " + compare)
    return compare;
  }
}

export function removeNum(_value:string) : string{
  var value:string = _value + ''
  if(/\d+$/.test(value)){
    value = value.slice(0,-1);
  }
  else if(/\d+/.test(value)){
    value = "";
  }
  return value
}

export function isDoubleByte(str:string) : boolean{
  for (var i = 0, n = str.length; i < n; i++) {
      if (str.charCodeAt( i ) > 255) { return true; }
  }
  return false;
}

//AD012345(6), first = AD, mid = 0123345, checkDigit = 6
export function isValidHkid(first:string, mid:string, checkDigit:string){
  var hkidre = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
	return hkidre.test(first + mid + checkDigit)
}

export function isValidMacauId(full:string){
  return true
  //var hkidre = /^((1|5|7){1})([0-9]{7})$/;
	//return hkidre.test(full)
}


//AD012345(6), first = AD, mid = 0123345, checkDigit = 6
export function isValidHkid4Digit(id4Digit:string){
  var hkid4digitre = /^[0-9]{4}$/;
	return hkid4digitre.test(id4Digit)
}

export function isValidMsisdn(msisdn:string) : boolean{
  var reg = /^[456789]{1}[0-9]{7}$/;
  if( !msisdn || msisdn.trim() == "" || !reg.test(msisdn)){
    return false
  }
  return true
}


export function isValidEmail(_email:string | null) : boolean{
  var email = _email ?? ''
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var re2 = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  if(email.trim().length == 0){
      return false
  }
  else if(!re.test(email)){
      return false
  }
  else if(!re2.test(email)){
      return false
  }
  return true
}

  export function validatedate(date) {
    
    var dateformat = /^(0[1-9]|[12][0-9]|3[01])[\/\-](0[1-9]|1[012])[\/\-]\d{4}$/;
    if(date.match(dateformat)){     
    var opera1 = date.split('/');
    var opera2 = date.split('-');
    var lopera1 = opera1.length;
    var lopera2 = opera2.length;    
    if (lopera1>1){
      var pdate = date.split('/');
    }else if (lopera2>1){
      var pdate = date.split('-');
    }
    var dd = parseInt(pdate[0]);
    var mm  = parseInt(pdate[1]);
    var yy = parseInt(pdate[2]);
     var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
    if (mm==1 || mm>2){
      if (dd>ListofDays[mm-1]){
      return false;
      }
    }
    if (mm==2){
      var lyear = false;
      if ( (!(yy % 4) && yy % 100) || !(yy % 400)) {
        lyear = true;
      }
      if ((lyear==false) && (dd>=29)){
        return false;
      }
      if ((lyear==true) && (dd>29)){
        return false;
      }
    }
      return true;
  }else
    {
    return false;
    }
  }

  export function handleIosScroll(){
    let inputFields = document.getElementsByTagName("INPUT");
    let ua = navigator.userAgent, iOS = /iPad|iPhone|iPod/.test(ua);
    if(inputFields && inputFields.length > 0 && iOS){
      log.it('util',"add .under-scroll")
      for(var i = 0; i < inputFields.length; i++ ){
        let iField = inputFields[i];
        iField.classList.add("under-scroll")
      }
    }
    var timer = setTimeout(function(){
      let inputFields = document.getElementsByTagName("INPUT");
      let ua = navigator.userAgent, iOS = /iPad|iPhone|iPod/.test(ua);
      if(inputFields && inputFields.length > 0 && iOS){
        log.it('util',"remove .under-scroll")
        for(var i = 0; i < inputFields.length; i++ ){
          let iField = inputFields[i];					
          if(iField.classList.value.indexOf("under-scroll") > -1){
            iField.classList.remove("under-scroll")
          }
        }
      }
    },200);
    return timer;
  }

  export function isAlphaNumeric(str) {
    var code, i, len;
  
    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (!(code > 47 && code < 58) && // numeric (0-9)
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123)) { // lower alpha (a-z)
        return false;
      }
    }
    return true;
  }
      