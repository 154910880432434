/* eslint-disable */
import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { withRouter } from "react-router-dom"
import { Route, Switch } from "react-router-dom"
import '../css/common/font.scss';

import Image from '../UiComponent/common/Image'
import Button from '../UiComponent/common/Button'
import HTMLText from '../UiComponent/common/HTMLText'

import PageNotFound from './PageNotFound'
import * as wording from '../js/wording'
import * as util from '../js/util'
import * as gaLogger from '../js/gaLogger'
import * as log from '../js/appLogger'
import {Config} from '../../data/Config'
import {AppData} from "../../data/AppData"
import {API} from "../../data/API"
import { UserProfile } from '../../model/fbb/session/UserProfile';
import { UserData } from '../../data/UserData';


import '../css/layout.scss';

//seperate load, so that it will generate another file, the main react file will not become so large
const A_AcqSubFlow = Loadable({
	loader: () => import('../../A_AcqSubFlow/A_AcqSubFlow.jsx' /* webpackChunkName: "A_AcqSubFlow" */ ),
	loading() {
	  return ''
	}
});


class ViewController extends Component {
	

	constructor(props) {
		super(props)

		this.state = AppData.status.getErrorPromptInfo()
		this.state.loadingStyle = ''
		this.state.isLoading = true
		this.state.isDidMount = false
		this.state.extraConfig = {}
		
		this.onRequestChangeFlow = this.onRequestChangeFlow.bind(this);
		this.changeLang = this.changeLang.bind(this);
		this.showError = this.showError.bind(this);
		this.hideErrorPrompt = this.hideErrorPrompt.bind(this);		
		this.hideShowLoading = this.hideShowLoading.bind(this);
		this.onBackHandler = this.onBackHandler.bind(this);
		
		this.callLiveChat = this.callLiveChat.bind(this);
		this.gotoPage = this.gotoPage.bind(this);
		

		this.touchmoved = false
		this.calledGotoPage = false
	}
	
	componentDidMount() {
		//initialization here...
		
		//listen to page change
		this.unlisten = this.props.history.listen((location, action) => {
			log.it('VC',"[onRouteChange] " + location.pathname + ', ' + action);
			//alert('listen')
			gaLogger.logPageView(location.pathname)

			if ( ( location.pathname == '/' || location.pathname == '')  && action =='POP' ){
				//log.d(util.getUrlVars())
				//this.onBrowserBackHandler(util.getUrlVars().page)
			}
		});

		
		window.onpopstate = function(event) {
			var url = document.location.href
			//alert(url)
			log.ot('popstate', event)
		    //log.ot('popstate', event)
			
			//alert(this.calledGotoPage)
			if ( !this.calledGotoPage ){
				this.calledGotoPage = false
				location.reload();
				return
			}
			this.calledGotoPage = false
			
			
		}.bind(this);
		

		//load default page
		
		//GA for first page load/refresh
		gaLogger.logPageView(this.props.location.pathname)

		document.getElementsByTagName("BODY")[0].addEventListener("touchend",function(e){
			//log.it('VC','handleTouchEnd');
			//log.it('VC','e in viewctnr.js=' + e);
			if(	this.touchmoved != true){		
				if (!this.isTextInput(e.target) && this.isTextInput(document.activeElement)) {
					document.activeElement.blur();
				}
			}

		}.bind(this))

		document.getElementsByTagName("BODY")[0].addEventListener("touchmove",function(e){
			//log.it('VC','handleTouchMove');
			this.touchmoved = true;
						
		}.bind(this))

		document.getElementsByTagName("BODY")[0].addEventListener("touchstart",function(e){	
			//log.it('VC','handleTouchStart');		
			this.touchmoved = false;		
		}.bind(this))

		// "/!/" is for hkbn fbb site redirect handling
		if ( this.props.history.location.pathname ==  '/' || this.props.history.location.pathname == '/!/' ){
			this.props.history.replace("/a/")	
		}

		const _this = this
		wording.getWording('/data/config.json').then((theConfig) => {

			if ( theConfig?.nonHkidDep != true ){
				Config.PASSPORT_PLAN_DEPOSIT = 0
				Config.PASSPORT_HOMETEL_DEPOSIT = 0
			}
			log.dt('nonHkidDep', Config.PASSPORT_PLAN_DEPOSIT)

			_this.setState({
				extraConfig: theConfig,
				isLoading:false,
				isDidMount: true
			});
		})

	}

	/*
	onBrowserBackHandler(step){
		
		if (step == null || step == ''){
				//alert('>1 ' + step + ', ' + location.href)
				this.props.history.goBack()	
				this.onBrowserBackHandler(util.getUrlVars().page)
		}else{
			switch(step){
				case 'a.a1':
					log.it('>>>>', this.props.history.location)
					//alert(1)
					this.props.history.goBack()
					this.onBrowserBackHandler(util.getUrlVars().page)
					break;
				case 'a.a2':
					this.gotoPage('replace','/a/a1');
					break;
				case 'a.a3':
					this.gotoPage('replace','/a/a2');
					break;
				case 'a.a4':
					this.gotoPage('replace','/a/a3');
					break;
			}
		}
	}*/


	isTextInput(node) {
		return ['INPUT'].indexOf(node.nodeName) !== -1;
	}
	componentWillUnmount() {
		this.unlisten();
	}
	
	changeLang(newLang){
		wording.setLang(newLang);
		this.setState( (state, props) => ({
			lang: wording.getLang()
		}) );
		this.updateUrlLang()
	}


	showError(category, errorMsgId, apiCode, errorCode, closeErrorBtnMsgId, isHints){
		if (!AppData.error.isLoaded(category) ){
			wording.getWording('/wording/Common/' + category + '.json').then(function (wordingJson){	//ErrorMessage.json
				AppData.error.add(category, wordingJson);
				this.showError(category, errorMsgId, apiCode, errorCode, closeErrorBtnMsgId, isHints);
			}.bind(this));
		}
		else{
			log.it('VC','errorMsgId=' + errorMsgId);
			var errorMsgInPrompt = '';
			var errorCodePrompt = ''
			if( errorMsgId ){
				var theMsg = AppData.error.get(category,errorMsgId) ?? AppData.error.get(Error.CAT_COMMON,"system_busy")
				errorMsgInPrompt = wording.get(theMsg);
			}
			if(errorMsgInPrompt == ''){
				errorMsgInPrompt = errorMsgId
			}
			if(errorCode && apiCode){			
				if( API.error && API.error[errorCode]!= null){
					errorCodePrompt = "[" + apiCode + "/" + API.error[errorCode].code + "]";
				}
				else if ( API.error ){
					errorCodePrompt = '[' + apiCode  +'/' + errorCode  + ']'
				}
				else{
					errorCodePrompt = '[' + apiCode  +'/-1' + ']'
				}				
			}
			var errorPromptCloseBtnText = {};
			if (closeErrorBtnMsgId && AppData.error.isExist(category, closeErrorBtnMsgId) ){	
				errorPromptCloseBtnText = AppData.error.get(category, closeErrorBtnMsgId);
			}else{
				if ( AppData.error.isExist(Error.CAT_COMMON,'default_close_btn') ){
					errorPromptCloseBtnText = AppData.error.get(Error.CAT_COMMON,'default_close_btn')
				}else{
					errorPromptCloseBtnText={"en":"OK","zh":"OK"}
				}
				
			}

			if (isHints){
				if ( Config.promptErrorToConsole ){
					log.ot('VC-ERROR', AppData.error[Error.CAT_COMMON]['hints_title'] + '\n' + errorMsgInPrompt)
				}
				else{
					AppData.status.setErrorPromptInfo({
						errorPromptShow : true,
						errorPromptTitle: AppData.error[Error.CAT_COMMON]['hints_title'],
						errorPromptDesc: errorMsgInPrompt ,
						errorPromptCloseBtnText : AppData.error[Error.CAT_COMMON]['close_btn']
					})
				}
				
			}
			else{
				if ( Config.promptErrorToConsole ){
					log.ot('VC-ERROR', errorMsgInPrompt + '\n' + errorCodePrompt)
				}
				else{
					AppData.status.setErrorPromptInfo({
						errorPromptShow : true,
						errorPromptTitle:"",
						errorPromptDesc: errorMsgInPrompt + '<br/>' + errorCodePrompt ,
						errorPromptCloseBtnText : errorPromptCloseBtnText
					})
				}
				
			}
			if ( Config.promptErrorToConsole ){
				this.setState(AppData.status.getErrorPromptInfo())
			}
		}
	
	}

	hideErrorPrompt(){
		AppData.status.setErrorPromptInfo({
			errorPromptShow : false,
			errorPromptDesc: '',
		})
		AppData.status.save()
		this.setState(AppData.status.getErrorPromptInfo())
	}

	hideShowLoading(action){
		log.it('VC',"show hiding = " + action) // + " ===================="
		if (action=="hide"){
			this.setState({isLoading:false, loadingStyle:''})
		}
		else if(action == "show"){
			this.setState({isLoading:true, loadingStyle:''})
		}
		else if ( action == 'cover' ){
			this.setState({isLoading:true, loadingStyle:'cover'})
		}
	}

	onBackHandler(){		
		var step = this.props.history.location.pathname
		step = step.replace(Config.pathname, '');
		switch(step){
			
			default : break;
		}
	}

	callLiveChat(){
		
		//alert('under construction')
		//code copy from hkbn site
		if ( Config.enableLiveChat ){
			/*
			if (window.openChat2) {
				window.openChat2(Config.env == "production" ? 'https://shop.smartone.com' : "https://webstage7a.smartone.com",
						  'Promotion_' + (wording.getLang() == 'en' ? 'E' : 'C'), 'promo', false, '');
			}*/
			if ( window.showChatContainer ){
				window.showChatContainer(true); 
			}
		}
	}

	render() {

		//log.ot('vc', 'render')

		return (
			<div id="viewController">
				{(!this.state.isDidMount || this.state.isLoading) && (
					<div id='loading-overlay' className={this.state.loadingStyle} >
						<div className={'loading-overlay-bg ' + this.state.loadingStyle} />
						{/**<Image className='loading-icon' src='/img/loading.gif'/>**/}
						<div className={'vcloading '}></div>
					</div>
				)}
				{this.state.errorPromptShow && (
					<div className='prompt-overlay' >
						<div className="error-prompt-popup">
							<div className="content">	
							
								<div className="popup-hints-title">
								<HTMLText className='f7-font bold ' text={wording.get(this.state.errorPromptTitle)}  />
								</div>
								<HTMLText className='f6-font popup-title regular' text={this.state.errorPromptDesc}  />
																
								<div className='popup-fix-bottom'>
									<div className='popup-button'> 
										<Button text={wording.get(this.state.errorPromptCloseBtnText)} className='redInnerButton f6-font regular' onClickAction={this.hideErrorPrompt} isActive={true}/>
									</div>								
								</div>
							</div>
						</div>
					</div>
				)}
				
				{this.state.isDidMount && (
				<Switch>					
					
					<Route path={ "/a/"} render={(props) => (
						<React.Fragment>							
							<A_AcqSubFlow {...props} vcCallLiveChat={this.callLiveChat} 
											vcChangeLang={this.changeLang} vcShowError={this.showError} 
											vcHideShowLoading={this.hideShowLoading}
											isPc={this.props.isPc}
											gotoPage={this.gotoPage}  />						
						</React.Fragment>
					)} />

					
					<Route component={PageNotFound} />
				</Switch>
				)}
			</div>
		);
	}

	

	//flowName = constant.flowName
	onRequestChangeFlow(flowName){
		log.it('VC','onRequestChangeFlow: ' + flowName)
		this.gotoPage('push', Config.pathname + flowName)
	}
	
	updateUrlLang(){
		var win = util.isLocal() ? window : window.parent
		var paramB4Hash = this.props.getFbbQueryParams()
		//paramB4Hash.lang = wording.getLang()
		var str = '?'
		for (var key in paramB4Hash){
			var value = paramB4Hash[key];
			str += key + '=' + value + '&'
		}

		//since not called history.push, add items after # to push state.
		var url = util.isLocal() ? location.href : parent.document.location.href
		var hashInx = url.indexOf('#')
		if ( hashInx != -1 ){
			var afterHash = url.substring(hashInx)
			str += afterHash
		}

		win.history.pushState(null, null, str)
	}

	//action: for special cases
	gotoPage(method, page, action, actionValue){
		
		this.calledGotoPage = true
		//alert('gotoPage: ' + method + ', ' + page)
        if ( method === 'push' ){
            this.props.history.push(page);
        }
        else if ( method === 'replace' ){
            this.props.history.replace(page);
		}
		//history.pushState(null, null, 'aaaddd')
		//window.parent.history.pushState();


		//log.ot('goto', this.props.history.location.pathname)
		//if ( !util.isLocal() ){
			var win = window //util.isLocal() ? window : window.parent
			var paramB4Hash = this.props.getFbbQueryParams()
			//log.ot('goto', paramB4Hash)
			paramB4Hash.page = page.replace('/a/', 'a.').replace('/', '.')
			//paramB4Hash.lang = wording.getLang()
			var str = '?'
			for (var key in paramB4Hash){
				var value = paramB4Hash[key];
				if ( key == 'g' && action != 'no_ga' ){
					continue
				}
				else if ( (page == '/a/a1' || page == '/a/a4') && key == 't' ) {
					continue
				}
				if ( key != 'fn'){ //fbb number
					str += key + '=' + value + '&'
				}
			}
			if ( action == 'off_num' ){
				str += 'fn=' + actionValue + '&'
			}

			if ( action == 'no_ga' ){
				str += 'g=n&'
			}

			var afterHashInx = location.href.indexOf('#') //.'#/a/a1' 
			var afterHash = location.href.substring(afterHashInx)
			win.history.replaceState(null, null, str + afterHash)
            //this.props.history.replace(afterHash);
			//alertalert(str)
		//}


    }

	


}

export default withRouter(ViewController);