//import * as util from './util.js'
// react-ga  
import * as log from './appLogger'
import {Config} from '../../data/Config';
import {AppData} from '../../data/AppData';
import GTM from 'react-gtm-module'
//import GTM4GA1 from 'react-gtm-module'

function isEnabled(){
    return Config.enableGTM && !AppData.status.isOfflineMode()
}

export function initGTM(){
    if ( !isEnabled() ) return
    log.it('GTM', 'initGTM')
    const tagManagerArgs = {
        gtmId: Config.GTM_ID
    }
    GTM.initialize(tagManagerArgs)
    
    
}

export function logPage(name: string){
    if ( !isEnabled() ) return
    log.it('GTM', 'logPage')
    /*
    const test = {
        dataLayer: {
            userId: '001',
            userProject: 'project',
            page: 'home'
        },
        dataLayerName: 'PageDataLayer'
    }
    GTM.dataLayer(test)*/
}

export function push(dataLayerJson){
    if ( !isEnabled() ) return
    log.ot('GTM DataLayer', dataLayerJson)
    GTM.dataLayer(dataLayerJson)
}

export function createGASinglePlanItem(aPlan, index, isEng: boolean){
    var formattedBandwidth = aPlan['bandwidth'] || ''
    if ( formattedBandwidth.indexOf('M') == formattedBandwidth.length - 1 ){
        formattedBandwidth = formattedBandwidth.substring(0, formattedBandwidth.length - 1 ) + 'mb'
    }
    var aGaItem = {
        "item_id": aPlan['planCode'] || '',
        "item_name": (aPlan['planDisplayName'] || {})[isEng ? 'en' : 'zh'],
        "currency": "HKD",
        "item_brand": "SmarTone Fibre BroadBand",
        "item_category": "fbb",
        "item_category2": formattedBandwidth + "_fbb",
        "item_category3": aPlan['contractPeriod'] + 'Months',
        "item_list_id": "fbb",
        "item_list_name": "FBB Portal",
        "price": aPlan['effPrice'] || '',
        "index": index + 1
    }
    return aGaItem
}

