import { AddOn } from './AddOn'
import { AddOnType } from '../FbbEnum'

//not ready at phase 1.
//Show wording in landing page. (wording in addon.json)
//a2's wording differ from a1.
export class AddOnHomePhone extends AddOn{

    protected isNewNum:boolean = true
    protected phoneNum:string | null = null
    protected isReserved:boolean = false

    protected ldCode:string | null = null
    protected depositNonResident:number = 0
    protected depositNonAutopay: number = 0
    protected monthPrepayment:number = 0

    protected isBasePlanSelected:boolean = false    //is selected
    
    constructor(){
        super()
        this.key = 'AddOnHomePhone'
        
        this.boxIconEn = ''
        this.boxIconZh = ''
        this.boxNameEn = ''
        this.boxNameZh = ''
        this.boxAmount = 0
        this.hasRedCounter = true

        /*
        this.landingIcon['en'] = '/img/addon/router.png'
        this.landingIcon['zh'] = '/img/addon/router.png'

        this.landingTitle['en'] = ''
        this.landingTitle['zh'] = ''
        this.landingDesc1['en'] = ''
        this.landingDesc1['zh'] = ''
        this.landingDesc2['en'] = ''
        this.landingDesc2['zh'] = ''
        */
       
        this.type = AddOnType.SUB_ADDON
    }

    public isAddOnSelected() : boolean{
        return this.isBasePlanSelected
    }

    public toggleSelected(){
        this.isBasePlanSelected = !this.isBasePlanSelected
    }

    public getIsBasePlanSelected() : boolean{
        return this.isBasePlanSelected
    }
    
    //=================================================================================
    

    //=================================================================================



}