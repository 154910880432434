/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';


/**
* @augments {Component<{  className:string,  text:string,  style :any>}
*/
class HTMLText extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			text : this.props.text,
			className : this.props.className
		};
		
	}
	
	componentDidMount() {		
	}
	
	componentWillUnmount() {
	}

	static getDerivedStateFromProps(props, state) {
		return {
			text:props.text, 
			className:props.className
		  };
	
		// Return null to indicate no change to state.
		//return null;
	}

	
	

	render() {
		return (
			<React.Fragment>
				<span style={this.props.style} className={this.state.className} dangerouslySetInnerHTML={{ __html:this.state.text}}></span>
			</React.Fragment>			
		);
	}
}

HTMLText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  style : PropTypes.any
}

export default HTMLText;