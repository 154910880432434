import { AddOn } from './AddOn'
//import * as wording from '../../../common/js/wording'
import { AddOnType, BuildingType, Technology, Operator } from '../FbbEnum'
import { UserData } from '../../../data/UserData';
import { AllCoverage } from '../../../model/fbb/session/AllCoverage';
import * as log from '../../../common/js/appLogger'

export class AddOnHometel extends AddOn{

    static readonly codesInfo = {
        public:{
            me:[
                {
                    planCode:'HW01F',
                    marketingCode:'MWB29',
                    ldCode:'LDJ24M68',
                    contract:24,
                }
            ],
            gpon:[
                {
                    planCode:'HW02F',
                    marketingCode:'MWB29',
                    ldCode:'LDJ24M68',
                    contract:24,
                },
                {
                    planCode:'HW02F',
                    marketingCode:'MWC14',
                    ldCode:'LDJ30M68',
                    contract:30, //28-Aug-2020: newly added
                }
            ]
        },
        private:{
            me:[
                {
                    planCode:'HW01F',
                    marketingCode:'MWB29',
                    ldCode:'LDJ24M68',
                    contract:24,
                }
            ],
            gpon:[
                {
                    planCode:'HW02F',
                    marketingCode:'MWB29',
                    ldCode:'LDJ24M68',
                    contract:24,
                },
                /*
                //09-Jul: rollback #945, so keep the contract month checking, but replace by 24month's code
                {
                    planCode:'HW02F',
                    marketingCode:'MWB29',
                    ldCode:'LDJ24M68',
                    contract:12,
                },*/
                {   //10-Jul-2020: newly added for contract month 12 plans
                    planCode:'HW02F',
                    marketingCode:'MXL83',
                    ldCode:'LDJ12M68',
                    contract:12,
                },
                {
                    planCode:'HW02F',
                    marketingCode:'MWC14',
                    ldCode:'LDJ30M68',
                    contract:30, //28-Aug-2020: newly added
                }
            ]
        },
        village:{   //no ME
            gpon:[
                {
                    planCode:'HW02F',
                    marketingCode:'MWB29',
                    ldCode:'LDJ24M68',
                    contract:24,
                },
                {
                    planCode:'HW02F',
                    marketingCode:'MWC14',
                    ldCode:'LDJ30M68',
                    contract:30, //28-Aug-2020: newly added
                }
            ]
        },
        callForward:{ vrsCode: 'HTCFE', ldCode:'' },
        speedDial:{ vrsCode: 'HTSDE', ldCode:'' },
        doNotDisturb:{ vrsCode: 'HTDNE', ldCode:'' }

    }


    protected isNewNum:boolean = true
    protected phoneNum:string | null = null
    protected isReserved:boolean = false
    protected isApproved: boolean = false

    protected isBasePlanSelected:boolean = false
    protected callForward:boolean = false
    protected speedDial:boolean = false
    protected doNotDisturb:boolean = false

    protected callForwardAmount:Number = 15
    protected speedDialAmount:Number = 15
    protected doNotDisturbAmount:Number = 15
    
    protected callForwardName = {en:'Call Forwarding', zh:'來 電 轉 駁',
        emailContent: {
            en:'Call Forwarding – HK$15 per month', zh:'來電轉駁 – 每月 HK$15', 
        }
    }
    protected speedDialName = {en:'Speed Dial', zh:'快 速 撥 號',
        emailContent: {
            en:'Speed Dial – HK$15 per month', zh:'快速撥號 – 每月 HK$15', 
        }
    }
    protected doNotDisturbName = {
        en:'Do-Not-Disturb', zh:'請 勿 干 擾', 
        emailContent: {
            en:'Do-Not-Disturb – HK$15 per month', zh:'請勿干擾 – 每月 HK$15', 
        }
    }



    constructor(){
        super()
        this.key = 'AddOnHometel'
        
        this.boxIconEn = ''
        this.boxIconZh = ''
        this.boxNameEn = 'Home Tel'
        this.boxNameZh = 'Home Tel'
        this.boxAmount = 68
        this.discountAmount = 40 //still pay $68 the 1st month.
        this.hasRedCounter = true
        /*
        this.landingIcon['en'] = '/img/addon/router.png'
        this.landingIcon['zh'] = '/img/addon/router.png'

        this.landingTitle['en'] = ''
        this.landingTitle['zh'] = ''
        this.landingDesc1['en'] = ''
        this.landingDesc1['zh'] = ''
        this.landingDesc2['en'] = ''
        this.landingDesc2['zh'] = ''
        */
        this.type = AddOnType.SUB_ADDON    
        
    }

    //=================================================================================

    public isAddOnSelected() : boolean{
        return this.isBasePlanSelected
    }

    public getData() : any {
        //var data = super.getData()
        var data = {
            key: this.key,
            isNewNum: this.isNewNum,
            phoneNum: this.phoneNum,
            isReserved: this.isReserved,
            isApproved: this.isApproved,
        }
        //log.ot('hometel getData', data)
        
        return data
    }
    public parse(data: any) {
        //super.parse(data)
        this.isNewNum = data.isNewNum ?? false
        this.phoneNum = data.phoneNum ?? ''
        this.isReserved = data.isReserved ?? false
        this.isApproved = data.isApproved ?? false
        //log.ot('hometel parse', data)
    }

    public getContractMonth(tech:String | null, buildingType: String | null, 
                contractMonth: String | null, op:Operator) : Number{

        //03-Jul-2020 - follow plans.

        if ( contractMonth == null ){
            return 0
        }
        
        try{
            return parseInt(contractMonth)
        }
        catch(e){
            return 0
        }

        /*
        var b = buildingType ?? ''
        if ( b.length === 0 ){ b = BuildingType.Public }
        b = b.toUpperCase()
        var t = tech ?? ''
        if ( t.length === 0 ) t = Technology.GP
        t = t.toUpperCase()

        var json = {}
        if ( b == BuildingType.Public ){ json = AddOnHometel.codesInfo.public ?? {} }
        else if ( b == BuildingType.Private ){ json = AddOnHometel.codesInfo.private ?? {} }
        else if ( b == BuildingType.Village ){ json = AddOnHometel.codesInfo.village ?? {} }

        if ( t == Technology.GP ){ json = json.gpon ?? {} }
        else if ( t == Technology.ME ){ json = json.me ?? {} }
        
        return (json ?? {}).contract ?? 0*/
    }

    public isHomeNumberReserved(): boolean {
        return this.isReserved
    }

    public isHometelApproved() : boolean {
        return this.isApproved 
    }

    public clearAllSubAddon() {
        this.callForward = false
        this.speedDial = false
        this.doNotDisturb = false
        this.setSelectedCount(0)
    }
    
    public setIsHomeNumberReserved(isReserved:boolean) {
        this.isReserved = isReserved
    }

    public getPhoneNum() : string | null {
        return this.phoneNum + ''
    }

    public getIsNewNum() : boolean {
        return this.isNewNum
    }

    public setCallForward(isSelected:boolean){
        this.callForward = isSelected
    }

    public setSpeedDial(isSelected:boolean){
        this.speedDial = isSelected
    }

    public setDoNotDisturb(isSelected:boolean){
        this.doNotDisturb = isSelected
    }

    public isSelectedCallForward() : boolean {
        return this.callForward
    }

    public isSelectedSpeedDial() : boolean {
        return this.speedDial
    }

    public isSelectedDoNotDisturb() : boolean {
        return this.doNotDisturb
    }

    public toggleSelected(){
        this.isBasePlanSelected = !this.isBasePlanSelected
    }

    public setIsBasePlanSelected(selected:boolean){
        this.isBasePlanSelected = selected
    }

    public getIsBasePlanSelected() : boolean{
        return this.isBasePlanSelected
    }

    public getCallForwardAmount() : Number{
        return this.callForwardAmount
    }
    
    public getSpeedDialAmount() : Number{
        return this.speedDialAmount
    }
    
    public getDoNotDisturbAmount() : Number{
        return this.doNotDisturbAmount
    }

    
    public getCallForwardName(lang:string) : string{
        return this.callForwardName[lang]
    }

    public getSpeedDialName(lang:string) : string{
        return this.speedDialName[lang]
    }

    public getDoNotDisturbName(lang:string) : string{
        return this.doNotDisturbName[lang]
    }

    public isAnySubAddOnSelected() : boolean{
        return this.isSelectedCallForward() || this.isSelectedDoNotDisturb() || this.isSelectedSpeedDial();
    }

    
    //=================================================================================

    public getOptionEmailContent(lang:string) : any{
        let emailContent = []
            
        if(this.isSelectedCallForward() ) {
            emailContent.push(this.callForwardName.emailContent[lang])
        }
        if(this.isSelectedSpeedDial() ) {
            emailContent.push(this.speedDialName.emailContent[lang])
        }
        if(this.isSelectedDoNotDisturb() ) {
            emailContent.push(this.doNotDisturbName.emailContent[lang])
        }
        
        return emailContent
    }

    /*
    {
      item_id: "fbb-addon-kkbox",
      item_name: "KKBOX (無合約)",
      currency: "HKD",
      item_brand: "KKBOX",
      item_category: "Services",
      item_list_id: "fbb",
      item_list_name: "FBB Portal",
      price: 49.00,
      quantity: 1
    }
    */
    //price handling reference OrderBox.
    public createGAItem(lang: string) : any{

        var itemList = []
        var main = {
            "item_id": "fbb-addon-hometel",
            "item_name": this.getName(lang),
            "currency": "HKD",
            "item_brand": "HOMETEL",
            "item_category": "Services",
            "item_list_id": "fbb",
            "item_list_name": "FBB Portal",
            "price": this.getAmount(null, null) || 0,
            "quantity": 1,
        }
        itemList.push(main)

        if ( this.isSelectedCallForward() ){
            var callForwardJson = {
                "item_id": "fbb-addon-hometel-callforward",
                "item_name": this.getCallForwardName(lang),
                "currency": "HKD",
                "item_brand": "HOMETEL-CALLFORWARD",
                "item_category": "Services",
                "item_list_id": "fbb",
                "item_list_name": "FBB Portal",
                "price": this.getCallForwardAmount() || 0,
                "quantity": 1,
            }
            itemList.push(callForwardJson)
        }
        if ( this.isSelectedSpeedDial() ){
            var speedDialJson = {
                "item_id": "fbb-addon-hometel-speeddial",
                "item_name": this.getSpeedDialName(lang),
                "currency": "HKD",
                "item_brand": "HOMETEL-SPEEDDIAL",
                "item_category": "Services",
                "item_list_id": "fbb",
                "item_list_name": "FBB Portal",
                "price": this.getSpeedDialAmount() || 0,
                "quantity": 1,
            }
            itemList.push(speedDialJson)
        }
        if ( this.isSelectedDoNotDisturb() ){
            var donotDisturbJson = {
                "item_id": "fbb-addon-hometel-donotdisturb",
                "item_name": this.getDoNotDisturbName(lang),
                "currency": "HKD",
                "item_brand": "HOMETEL-DONOTDISTURB",
                "item_category": "Services",
                "item_list_id": "fbb",
                "item_list_name": "FBB Portal",
                "price": this.getDoNotDisturbAmount() || 0,
                "quantity": 1,
            }
            itemList.push(donotDisturbJson)
        }
        return itemList
    }


}