/* eslint-disable */

import * as wording from '../../../common/js/wording'
//import {Coverage} from './Coverage'
import {ASession} from '../../common/ASession'
import * as log from '../../../common/js/appLogger'

//whenever add new type, pls update parseErrorCode() 
export enum FbbErrorType {
    None = '-',
    NoCoverage = 'NO_COVERAGE',
    NoPort = 'NO_PORT',
    NoPlan = 'NO_PLAN',
    Only100m200mPlan = 'ONLY_100M_200M',
    FailReserveHometel = 'FAIL_RESERVE_HOMETEL',
    invalidHkidBr = 'INVALID_HKIDBR',
    NotOwner = 'NOT_OWNER',
    NotApproved = 'NOT_APPROVED',
    BlockPlanGroup = "PLAN_GROUP_SPH3", //St Martin SPH3
    systemBusy = 'SYSTEM_BUSY'
}

//Use at a6 sorry page.
export class FbbError extends ASession {
    
    protected sessionKey:string = "fbbError"

    protected errorType:FbbErrorType = FbbErrorType.None
    //protected message:any = {en:'', zh:''}

//=================================================================================

    constructor(){
        super()
        this.setData(this.getDefaultData())
    }

    
    protected getDefaultData() : any{
        return {
            errorType: this.errorType,
        }
    }

    protected getData() : any {
        //throw new Error("Method not implemented.")
        return {
            errorType:this.errorType,
        }

    }
    protected setData(data: any) {
        //log.ot('fbbError.setData', data)
        this.parseErrorCode(data.errorType ?? '')
    }

    //=================================================================================

    parseErrorCode(code:string){
        //log.it('parseErrorCode', code)
        if ( code === 'NO_COVERAGE' ){
            this.errorType = FbbErrorType.NoCoverage
        }
        else if ( code === 'NO_PORT' ){
            this.errorType = FbbErrorType.NoPort
        }
        else if ( code === 'NO_PLAN' ){
            this.errorType = FbbErrorType.NoPlan
        }
        else if ( code === 'ONLY_100M_200M' ){
            this.errorType = FbbErrorType.Only100m200mPlan
        }
        else if ( code === 'FAIL_RESERVE_HOMETEL' ){
            this.errorType = FbbErrorType.FailReserveHometel
        }
        else if ( code === 'INVALID_HKIDBR' ){
            this.errorType = FbbErrorType.invalidHkidBr
        }
        else if ( code === 'NOT_OWNER' ){
            this.errorType = FbbErrorType.NotOwner
        }
        else if ( code === 'NotApproved' ){
            this.errorType = FbbErrorType.NotApproved
        }
        else if ( code === 'SYSTEM_BUSY' ){
            this.errorType = FbbErrorType.systemBusy
        }
        else if ( code === 'PLAN_GROUP_SPH3' ){
            this.errorType = FbbErrorType.BlockPlanGroup
        }
        else{
            this.errorType = FbbErrorType.None
        }


    }

    getErrorMessageKey() : string{
        return this.errorType.valueOf()
    }


    setErrorType(type:FbbErrorType){
        this.errorType = type
    }

    getErrorType() : FbbErrorType{
        return this.errorType
    }

}