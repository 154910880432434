/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Config} from '../../../data/Config'


/**
* @augments {Component<{  className:string,  src:string, srcOn:string, style:any, isSrcBase64:boolean>, 
						  onClick:function, onMouseOver:function, onMouseOut:function}
*/
class Image extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			uid:this.props.uid ?? '',
			src : this.props.src,
			srcOn: this.props.srcOn,
			className : this.props.className,
			isSrcBase64 : this.props.isSrcBase64,
			isMouseOver:false,
			
		};
		
	}
	
	componentDidMount() {		
	}
	
	componentWillUnmount() {
	}


	static getDerivedStateFromProps(props, state) {
		return {
			uid:props.uid ?? '',
			src:props.src, 
			srcOn: props.srcOn,
			className:props.className
		};
	
		// Return null to indicate no change to state.
		//return null;
	}

	

	onMouseOver(){
		if ( this.state.srcOn != null && this.state.srcOn != undefined ){
			this.setState({
				isMouseOver:true
			})
			if ( this.props.onMouseOver != null && this.props.onMouseOver != undefined ){
				this.props.onMouseOver(this)
			}
		}
	}

	onMouseOut(){
		if ( this.state.srcOn != null && this.state.srcOn != undefined ){
			this.setState({
				isMouseOver:false
			})
			if ( this.props.onMouseOut != null && this.props.onMouseOut != undefined ){
				this.props.onMouseOut(this)
			}
		}
	}

	onClick(){
		if ( this.props.onClick != null && this.props.onClick != undefined ){
			this.props.onClick(this)
		}
	}
	

	render() {
		var filePrefix = '';
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			filePrefix = process.env.PUBLIC_URL
		} else {
			filePrefix = Config.filepathname 
		}


		const onErrorSrc = "/img/image_not_found.png" 
		//avoid image still load with say http://xxxx/undefined
		var imgToUse = this.state.isMouseOver ? this.state.srcOn : this.state.src
		var noImage = (imgToUse == null || imgToUse == undefined)
		var imgWithPrefix = noImage ? filePrefix + onErrorSrc + '' : filePrefix + imgToUse
		var imgWithoutPrefix = noImage ? onErrorSrc+'' : imgToUse

		const onErrorImgWithPrefix = filePrefix + onErrorSrc;
		const onErrorImgWithoutPrefix = onErrorSrc;
		

		return (
			<React.Fragment >
				{(this.props.isSrcBase64 == null || this.props.isSrcBase64 == false) && ( 
					<img id={this.state.uid} ref={this.props.refName} style={this.props.style} className={this.state.className} src={imgWithPrefix}  alt=""
						 onMouseOver={ (e) => this.onMouseOver() }
						 onMouseOut={ (e) => this.onMouseOut() } onClick={() => this.onClick()}  
						 onError={(e)=>{
							 			e.target.onerror = null; 
										if ( e.target?.src != onErrorImgWithPrefix ){ 
											e.target.src=onErrorImgWithPrefix; 
										} 
									   }}
						 />
				)}

				{this.props.isSrcBase64 != null && this.props.isSrcBase64 == true && ( 
					<img id={this.state.uid} ref={this.props.refName} style={this.props.style} className={this.state.className} src={imgWithoutPrefix}  alt=""
					onMouseOver={ (e) => this.onMouseOver() }
					onMouseOut={ (e) => this.onMouseOut() }
					onClick={() => this.onTap()}  
					onError={(e)=>{e.target.onerror = null; if ( e.target?.src != onErrorImgWithoutPrefix ){ 
						e.target.src=onErrorImgWithoutPrefix; 
					} }}
					/>
				)}
			</React.Fragment>			
		);
	}
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  style:PropTypes.any,
  isSrcBase64:PropTypes.bool
}

export default Image;