/* eslint-disable */
import React, { Component } from 'react';
import '../../css/common/Button.scss';
import PropTypes from 'prop-types';
import IMAGE from  './Image';



/**
* @augments {Component<{  className:string,  onClickAction:Function,  text:string,  
	icon:string,  isActive bool,  fontSize string,  numberWordingnumber, textClassName:string>}
*/
class Button extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			uid:this.props.uid ?? '',
			text : this.props.text,
			isActive: this.props.isActive == undefined ? true : this.props.isActive,
			className : this.props.className,
			icon: this.props.icon,
			iconAfter: this.props.iconAfter,
			numberWording:this.props.numberWording,
			textClassName:this.props.textClassName,
			showLoading:this.props.showLoading,
		};
		
	}
	
	componentDidMount() {		
	}
	
	componentWillUnmount() {
	}

	
	static getDerivedStateFromProps(props, state) {
		return {
			uid:props.uid ?? '',
			text:props.text, 
			className:props.className, 
			icon:props.icon,
			isActive:props.isActive,
			iconAfter:props.iconAfter,
			numberWording:props.numberWording,
			textClassName:props.textClassName,
			showLoading:props.showLoading,
		  };
	
		// Return null to indicate no change to state.
		//return null;
	}

	

	render() {

		var fontSize = this.props.fontSize == undefined ? "app-button-common" : this.props.fontSize

		var inactiveClass = (this.state.className == 'redButton' ? 'redButtonDisabled' : 'redButtonDisabled')
		var className = this.state.className + ' ' + (this.state.isActive ? '' : inactiveClass) + ' ' + fontSize
		var onClickAction = this.state.isActive ? this.props.onClickAction : () => {  }

		return (
			<React.Fragment>				
				<div id={this.state.uid} className={className}  onClick={onClickAction} >
						
						{ this.state.icon == undefined &&  this.state.iconAfter == undefined && (
							<div className="button-icon-row">
								<div className={this.state.textClassName}>{this.state.text}
								</div>
								{ this.state.numberWording != undefined && (
									<div class="number-icon">{this.state.numberWording}</div>
								)}
								{this.state.showLoading && (
									<div className='loading' style={{}}/>
								)}
							</div>
						)}

						{( this.state.icon != undefined || this.state.iconAfter != undefined )&&  (
							<div className="button-icon-row">
								{ this.state.icon != undefined &&
								<IMAGE className="button-icon" src={this.state.icon} />
								}
								<div className={this.state.textClassName}>{this.state.text}</div>
								{ this.state.iconAfter != undefined &&
								<IMAGE className="button-icon button-icon-after" src={this.state.iconAfter} />
								}
							</div>
						)}

				</div>				
			</React.Fragment>			
		);
	}
}

Button.propTypes = {
  className: PropTypes.string,
  onClickAction: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
  isActive:PropTypes.bool,
  fontSize:PropTypes.string,
  numberWording:PropTypes.number
}



export default Button;