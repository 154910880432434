
import * as apiLocal from '../_api/api_local.json'
import * as apiDev from '../_api/api_dev.json'
import * as apiStg from '../_api/api_stg.json'
import * as apiPreProd from '../_api/api_preprod.json'
import * as apiProd from '../_api/api_prod.json'
import * as util from '../common/js/util'
import { Operator, Bandwidth, Technology, CustomerType } from '../model/fbb/FbbEnum'


export class API {
    static api?: any
    static error?: any

    static setup(){

        if ( util.isLocal() ){
            API.api = apiLocal.default.api
            API.error = apiLocal.default.error
		} 
		else if ( util.isDev() ){
            API.api = apiDev.default.api
            API.error = apiDev.default.error
        }
        else if ( util.isStg() ){
            API.api = apiStg.default.api
            API.error = apiStg.default.error
        }
        else if ( util.isPreProd() ){
            API.api = apiPreProd.default.api
            API.error = apiPreProd.default.error
        }
        else if ( util.isProd() ){
            API.api = apiProd.default.api
            API.error = apiProd.default.error
		}
    }

    static parseOperator(operator:string) : Operator {
        if ( operator === 'HKBN'){
            return Operator.hkbn
        }
        else if ( operator === 'HGC'){
            return Operator.hgc
        }
        else if ( operator === 'ICABLE' || operator === 'CABLE' ){
            return Operator.icable
        }
        return Operator.hkbn
    }

    static parseBandwidth(bandwidth:string) : Bandwidth{
        
        if ( bandwidth === '1000' ){
            return Bandwidth.b1000
        }
        else if ( bandwidth === '100' ){
            return Bandwidth.b100
        }
        else if ( bandwidth === '200' ){
            return Bandwidth.b200
        }
        else if ( bandwidth === '2000' ){
            return Bandwidth.b2000
        }
        else if ( bandwidth === '500' ){
            return Bandwidth.b500
        }
        else{
            return Bandwidth.b1000
        }

    }

    static parseCustomerType(type:string) : CustomerType {

        if ( type === 'SMC'){
            return CustomerType.SMC
        }
        else if ( type === 'MASS'){
            return CustomerType.MASS
        }
        return CustomerType.MASS
    }

    static parseTechnology(tech:string) : Technology {

        if ( tech === 'DOS'){
            return Technology.DOS
        }
        else if ( tech === 'GP'){
            return Technology.GP
        }
        else if ( tech === 'ME'){
            return Technology.ME
        }
        return Technology.GP
    }
} 