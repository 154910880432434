
import {Config} from '../../data/Config'

//0 = DEBUG
//1 = INFO
//2 = ERROR

//debug
export function d(obj:any){
    if ( Config.printLog <= 0 ){
        console.log('{d} ' + obj)
    }
}

//debug with tag
export function dt(tag:string, obj:any){
    if ( Config.printLog <= 0 ){
        //console.log('{d} [' + tag + '] ' + obj)
        console.log('%c{d} [' + tag + '] ' + ((typeof obj == 'object') ? '%o' : '%s') ,
                        'color:#88B991',  obj )
    }
}



//info
export function i(obj:any){
    if ( Config.printLog <= 1 ){
        console.log(obj)
    }
}

//info with tag
export function it(tag:string, obj:any){
    if ( Config.printLog <= 1 ){
        console.log('[' + tag + '] ' + obj)
    }
}

//object with tag
export function ot(tag:string, obj:any){
    if ( Config.printLog <= 1 ){
        console.log('----------- S.' + tag + ' -----------')
        console.log(obj)
        console.log('----------- E.' + tag + ' -----------')
    }
}

//debug object with tag
export function dot(tag:string, obj:any){
    if ( Config.printLog <= 0 ){
        console.log('{d} ----------- S.' + tag + ' -----------')
        console.log(obj)
        console.log('{d} ----------- E.' + tag + ' -----------')
    }
}

export function e(obj:any){
    if ( Config.printLog <= 2 ){
        console.log('{e} S.ERROR ----------------------------------')
        console.log(obj)
        console.log('{e} E.ERROR ----------------------------------')
    }
}

//log GA in another file/method