/* eslint-disable */

import { AddOnType } from '../FbbEnum'
//import { AddOnMyTV } from './AddOnMyTV'
//import { AddOnRouter } from './AddOnRouter'
import * as log from '../../../common/js/appLogger'
//import { Plan } from '../session/Plan'
import { Technology, Operator } from '../FbbEnum'
import * as wording from '../../../common/js/wording'

export class AddOn {

    protected key:string = 'AddOn' //map to addon.json's id field
    protected elementId:string = '' //GTM

    protected boxIconEn:string = ''
    protected boxIconZh:string = ''
    protected boxNameEn:string = ''
    protected boxNameZh:string = ''
    protected boxAmount:number = 0
    protected boxCount:Number = 0
    protected discountAmount:Number = -1
    protected hasRedCounter = false

    /*
    protected landingIcon:{en:string, zh:string} = {en:'', zh:''}
    protected landingTitle:{en:string, zh:string} = {en:'', zh:''}
    protected landingDesc1:{en:string, zh:string} = {en:'', zh:''}
    protected landingDesc2:{en:string, zh:string} = {en:'', zh:''}
    */
    protected json:any = {} //addon.json
    protected fullJsonList:any = {}

    protected type:AddOnType = AddOnType.SINGLE

//=================================================================================

    //override at sub class please
    public isAddOnSelected() : boolean{
        return false
    }
    public getKey() : string {
        return this.key + ''
    }
    public isShowRedCounter(){
        return this.hasRedCounter;        
    }


    public getData() : any {
        return {
            key: this.key,
        }
    }
    public parse(data: any) {
        this.key = data.key
    }

    public getName(lang:string){
        return this.getLandingBoxTitle(lang)
    }

    public getShortName(lang:string){
        return this.getLandingBoxShortTitle()
    }

    public getIconPath(lang:string){
        return this.getLandingBoxIconPath()        
    }
    
    public getContractMonth(tech:String | null, buildingType: String | null, 
            contractMonth: String | null, op:Operator) : Number{
        return 0
    }

    public getBoxAmount(contractMonth:String | null, op: Operator) : Number{
        return this.boxAmount
    }

    // public getAmount(contractMonth:String | null) : Number{
    //     return this.boxAmount
    // }

    public getAmount(contractMonth:String | null, op: Operator | null) : Number{
        return this.boxAmount
    }

    public getDiscountAmount() : Number{
        return this.discountAmount
    }

    public getSelectedCount(): Number {
        return this.boxCount
    }

    public setSelectedCount(count:Number){
        this.boxCount = count
    }

    public getAddOnType() : AddOnType{
        return this.type
    }
    
    //parse config.json
    public parseConfig(json:any | null) {

        log.it('[SPH7] parseConfig', this.getKey())
        // log.ot('parseConfig -> ', json[this.getKey()])
        if ( json != null && json[this.getKey()] != undefined ){
            //log.dt('AddOn parseConfig', 'parse ' + this.getKey())
            this.json = json[this.getKey()] ?? {}
        }
        else {
            //log.dt('AddOn parseConfig', 'empty')
            this.json = {}
        }
        this.fullJsonList = json
    }

    //this to be replace those like HomePhonePlusOptionList
    public getConfigJson() : any | null {
        if ( this.json['config'] != undefined ){
            return this.json['config']
        }
        return null
    }

    //key format: <getKey()>___<conigKey>
    //e.g. AddOnHomePhone___SPH7
    public switchConfig(configKey:string){  
        //log.dt('AddOn switchConfig', 'configKey: ' + configKey)
        //log.ot('AddOn this.fullJsonList', this.fullJsonList)
        if ( !this.fullJsonList ){
            log.dt('[SPH7] AddOn switchConfig', 'fullJsonList is null')
            return
        }
        if ( this.fullJsonList[this.getKey() + '___' + configKey] != undefined ){
            log.dt('[SPH7] AddOn switchConfig', 'switch to ' + this.getKey() + '___' + configKey)
            this.json = this.fullJsonList[this.getKey() + '___' + configKey]
        }
        else { //switch to default one
            log.dt('[SPH7] AddOn switchConfig', 'switch to ' + this.getKey())
            this.json = this.fullJsonList[this.getKey()] ?? {}
        }
    }

    public getLandingElementId() : string{
        var box = this.json['box'] ?? {}
        //log.ot('getElementId', box)
        return box['elementId'] ?? ''
    }

    public getSelectPlanElementId() : string{
        var box = this.json['box'] ?? {}
        //log.ot('getElementId', box)
        return box['planElementId'] ?? ''
    }

    public getLandingBoxIconPath() : string{
        var box = this.json['box'] ?? {}
        return wording.get(box['icon'] ?? '') 
    }

    public getA2BoxIconPath() : string{
        var box = this.json['box'] ?? {};
        var icon = box['icon'];
        var iconA2 = box['icon_a2'];
        return wording.get(iconA2 ?? icon ?? ''); 
    }

    public getA2BoxIconPath2() : string{
        var box = this.json['box'] ?? {};
        var icon = box['icon2'];
        var iconA2 = box['icon2_a2'];
        return wording.get(iconA2 ?? icon ?? ''); 
    }

    public getA2BoxIconPath3() : string{
        var box = this.json['box'] ?? {};
        var icon = box['icon3'];
        var iconA2 = box['icon3_a2'];
        return wording.get(iconA2 ?? icon ?? ''); 
    }

    public getLandingBoxTitle(lang:string) : string{
        var box = this.json['box'] ?? {}
        if ( lang == wording.ENGLISH ){
            return (box['title'] ?? '')['en']
        }
        else if ( lang == wording.CHINESE ){
            return (box['title'] ?? '')['zh']
        }
        return wording.get(box['title'] ?? '') 
    }
    public getLandingBoxShortTitle() : string{
        //log.dot('addOn json', this.json)
        var box = this.json['box'] ?? {}
        return wording.get(box['shorttitle'] ?? '') 
    }
    public getLandingBoxDesc1() : string{
        var box = this.json['box'] ?? {}
        return wording.get(box['desc1'] ?? '') 
    }
    public getLandingBoxDesc2() : string{
        var box = this.json['box'] ?? {}
        return wording.get(box['desc2'] ?? '') 
    }

    public getLandingDetailsIcon() : string{
        var box = this.json['details'] ?? {}
        return wording.get(box['icon'] ?? '') 
    }

    public getA2DetailsIcon() : string{
        var box = this.json['details'] ?? {};
        var icon = box['icon'];
        var iconA2 = box['icon_a2'];
        return wording.get(iconA2 ?? icon ?? ''); 
    }
    
    public getA2DetailsIcon2() : string{
        var box = this.json['details'] ?? {};
        var icon = box['icon2'];
        return wording.get(icon ?? ''); 
    }
    
    public getA2DetailsIcon3() : string{
        var box = this.json['details'] ?? {};
        var icon = box['icon3'];
        return wording.get(icon ?? ''); 
    }

    public getLandingDetailsTitle() : string{
        var box = this.json['details'] ?? {}
        return wording.get(box['title'] ?? '') 
    }

    public getLandingDetailsSubtitle() : string{
        var box = this.json['details'] ?? {}
        return wording.get(box['subtitle'] ?? '') 
    }

    public getLandingDetailsDescHtml() : string{
        var box = this.json['details'] ?? {}
        return wording.get(box['descHtml'] ?? '') 
    }
    public getLandingDetailsDescHtml2() : string{
        var box = this.json['details'] ?? {}
        return wording.get(box['descHtml2'] ?? '') 
    }

    public getLandingDetailsDescHtml3() : string{
        var box = this.json['details'] ?? {}
        return wording.get(box['descHtml3'] ?? '') 
    }

    public getLandingAndroidLink() : string{
        var box = this.json['details'] ?? {}
        return box['androidLink'] ?? {}
    }
    public getLandingIosLink() : string{
        var box = this.json['details'] ?? {}
        return box['iosLink'] ?? {}
    }

    public getDetailsText(key:string) : string{
        var box = this.json['details'] ?? {}
        //log.ot('>>getDetailsText', wording.get(box[key] ?? '') )
        return wording.get(box[key] ?? '') 
    }

    public getLandingDetailsTnc() : string{
        var box = this.json['details'] ?? {}
        return wording.get(box['tncHtml'] ?? '') 
    }

    public getLandingDetailsTnc2() : string{
        var box = this.json['details'] ?? {}
        return wording.get(box['tncHtml2'] ?? '') 
    }

    public getTncLinkList() : any{
        //log.ot('>>getTncLinkList', this.json)
        var list = this.json['tncLinkList'] ?? []
        return list
    }

    public getDetailTncHtmlList() : any{
        //log.ot('>>getTncHtmlList', this.json)
        var list = this.json["details"]['tncHtml'] ?? {}
        return list
    }

    /*
    
    
    public getLandingDesc1(){
        return wording.get(this.landingDesc1)
    }
    public getLandingDesc2(){
        return wording.get(this.landingDesc2)
    }*/

}