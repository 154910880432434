import { AddOn } from './AddOn'
import { AddOnType } from '../FbbEnum'
import * as wording from '../../../common/js/wording';
import * as log from '../../../common/js/appLogger';

//!!!!! metehod createGAItem depends on this list. Please check against.
//this is smc one. hometel is hkbn one.
// export const HomePhonePlusOptionList = [
    
//     {
//         "order": 0,
//         "vasCode": "",
//         "marketCode": "NAZ19",  //ori_marketCode: MWV54
//         "ldCode": "",
//         "key": "home_phone_plus",
//         "fee": 0,
//         "gaClickEvent": "home_phone_plus",
//         "title": {
//             "en": "HomePhone+<br/><div class=\"font14\">Enjoy monthly $20 service fee rebate for subscription with Home Phone+ Service</div>",
//             "zh": "家+電話<br/><div class=\"font14\">同時選用家+電話服務，每月額外享$20月費回贈</div>"
//         },
//         // "details": {
//         //     "en": "<ul class='discList2'><li>Norton Security – Provide tech defends against viruses, spyware, phishing web/ app and other online threats </li><li>Norton Secure VPN - Bank-grade encryption ensures information you send and receive is secure </li><li>Norton Family – Family features so your kids can explore their connected world safely </li><li class='bold color-r'>Enjoy 50% off for 24 months contract</li></ul>",
//         //     "zh": "<ul class='discList2'><li>諾頓網絡安全 - 抵禦病毒、間諜程式、惡意軟體和其他線上威脅</li><li>諾頓安全VPN - 使用銀行等級加密技術，協助確保傳送及接收的資訊安全且私密</li><li>諾頓家長防護網 - 提供家庭防護網安全功能，讓孩子能夠安全地探索網路世界</li><li class='bold color-r'>簽訂24個月合約即享半價優惠</li></ul>"
//         // },
//         "emailContent":{
//             "en": "HomePhone+ Service<br/>(The service registration is not yet completed at this stage, our customer service specialist will contact you later to follow up on the application for HomePhone+ Service.)",
//             "zh": "家+電話服務計劃<br/>(服務尚未完成登記，稍後會有客戶服務專員與你聯絡，跟進家+電話服務申請事宜。)"
//         },
//         "orderBoxTitle":{
//             "en": "HomePhone+ Service",
//             "zh": "家+電話服務"
//         },
//         "orderBoxSubtitle":{
//             "en": "(The service registration is not yet completed at this stage, our customer service specialist will contact you later to follow up on the application for HomePhone+ Service.)",
//             "zh": "(服務尚未完成登記，稍後會有客戶服務專員與你聯絡，跟進家+電話服務申請事宜。)"
//         }
//     }
// ]


export enum AddOnHomePhonePlusPlan{
    NONE = -1,
    Plan1 = 0,
    //Plan5 = 4,    
}

export class AddOnHomePhonePlus extends AddOn{

    protected selectedPlanList:Array<any> = []
    protected maxPlanSelected:number = 1;
    protected planIndex:AddOnHomePhonePlusPlan = AddOnHomePhonePlusPlan.NONE //Helen, please rename if needed
    protected plan:any = null;

    constructor(){
        super()
        this.key = 'AddOnHomePhone' //json name
        
        this.boxIconEn = ''
        this.boxIconZh = ''
        this.boxNameEn = 'AddOnHomePhonePlus'
        this.boxNameZh = 'AddOnHomePhonePlus'
        this.boxAmount = 0
        this.hasRedCounter = false

        this.type = AddOnType.CHOOSE1
        
    }

    public isAddOnSelected() : boolean{
        return this.planIndex !== AddOnHomePhonePlusPlan.NONE
    }

    public setPlanList(planIndex:AddOnHomePhonePlusPlan){
        this.planIndex = planIndex
        if(this.selectedPlanList.length >= this.maxPlanSelected){
            this.selectedPlanList.pop()
        }
        // this.setPlan(HomePhonePlusOptionList[planIndex])
        this.setPlan(this.getConfigJson()) //type = AddOnType.CHOOSE1, so like this.
        this.selectedPlanList.push(this.plan)     
    }

    public setDefaultPlan(){
        this.setPlanList(AddOnHomePhonePlusPlan.Plan1)
        this.setSelectedCount(1)
    }

    public getOrderBoxTitle(lang:String){
        //log.dot('getOrderBoxTitle', HomePhonePlusOptionList[this.planIndex]['orderBoxTitle'][lang])
        // return HomePhonePlusOptionList[this.planIndex]['orderBoxTitle'][lang] ?? ''
        //log.dt('[SPH7] getOrderBoxTitle', this.getConfigJson())
        return (this.getConfigJson() ?? {})['orderBoxTitle'][lang] ?? ''
    }

    public getOrderBoxSubTitle(lang:String){
        //log.dot('getOrderBoxSubTitle', HomePhonePlusOptionList[this.planIndex]['orderBoxSubtitle'][lang])
        //return HomePhonePlusOptionList[this.planIndex]['orderBoxSubtitle'][lang] ?? ''
        return (this.getConfigJson() ?? {})['orderBoxSubtitle'][lang] ?? ''
    }

    public unselectPlan(){
        this.planIndex = AddOnHomePhonePlusPlan.NONE
        this.selectedPlanList = []
        this.plan = null;
    }

    public getPlanList() : Array<any> {
        //return this.plan
        return this.selectedPlanList;
    }
    public setPlan(plan:any){
        this.plan = plan;
    }
    public getPlan() : any {
        return this.plan
    }

    public getPlanIndex() : number {
        //return this.plan
        return this.planIndex;     
    }

    public getPlanByIndex(planIndex: number) : any {
        if ( planIndex === AddOnHomePhonePlusPlan.NONE ){
            return null;
        }
        else { 
            // return HomePhonePlusOptionList[planIndex]
            return this.getConfigJson()
        }  
    }

    public getPlanPrice() : number {
        if ( this.planIndex === AddOnHomePhonePlusPlan.NONE ){
            return 0;
        }
        else { 
            return (this.plan ?? {}).fee ?? 0
        }  
    }

    public getPlanVasCode() : string {
        if ( this.planIndex === AddOnHomePhonePlusPlan.NONE ){
            return ""
        }
        else{
            return this.plan.vasCode
        }  
    }


    public getPlanMktCode() : string {
        if ( this.planIndex === AddOnHomePhonePlusPlan.NONE ){
            return ""
        }
        else{
            return this.plan.marketCode
        }
    }


    public getPlanLdCode() : string {
        if ( this.planIndex === AddOnHomePhonePlusPlan.NONE ){
            return ""
        }
        else{
            return this.plan.ldCode
        }
    }

    public getOptionEmailContent(lang:string) : any{
        let emailContent = []
        if ( this.planIndex === AddOnHomePhonePlusPlan.NONE ){}
        else { 
            emailContent.push(this.plan.emailContent[lang])
        }  
        return emailContent
    }

    //price handling reference OrderBox.
    public createGAItem(lang: string) : any{

        var selected = this.getPlanList()[0]
        var cutTitle = selected['title'][lang]
        if ( cutTitle.indexOf('<br/>') != -1 ){
            cutTitle = cutTitle.substring(0, cutTitle.indexOf('<br/>'))
        }
        var main = {
            "item_id": "fbb-addon-homephoneplus",
            "item_name": cutTitle,
            "currency": "HKD",
            "item_brand": "HOMEPHONEPLUS",
            "item_category": "Services",
            "item_list_id": "fbb",
            "item_list_name": "FBB Portal",
            "price": selected['fee'],
            "quantity": 1,
        }
        return main
    }
}