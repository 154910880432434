
import {ASession} from '../../common/ASession'
//import {Coverage} from './Coverage'
import { CustomerType, CurrentISP, OfflinePaymentType } from '../FbbEnum'
import * as log from '../../../common/js/appLogger'

export enum IDType {
    hkid = 'hkid',
    passport = 'passport',
    eep = 'eep',
    macauId = 'macauId',
    chinaPassport = 'chinaPassport'
}

export class UserProfile extends ASession {

    //for smartone cash payment method accounts.
    static readonly cashDepositInfo = {
        hkbn:{
            gpon:{ amount: 1500, desc:'cashDepositPlanDesc' }, //key of Comp_OrderBox wording file
            me:{ amount: 0, desc:'cashDepositPlanDesc' },
            hometel: { amount: 360, desc:'cashDepositHometelDesc' }
        },
        icable:{
            gpon:{ amount: 1500, desc:'cashDepositPlanDesc' },
            dos:{ amount: 500, desc:'cashDepositPlanDesc' },
        },
        hgc:{
            gpon:{ amount: 1500, desc:'cashDepositPlanDesc' },
            me:{ amount: 500, desc:'cashDepositPlanDesc' },
        }

    }

    protected sessionKey: string = "UserInfo"

    protected customerType:CustomerType = CustomerType.MASS

    protected customerNumber:string = ""
    protected fbbNumber:string = "" //subscriberNumber
    
    protected idType:IDType = IDType.hkid
    protected title:string = ""  //Mr, Mrs
    protected firstName:string = ""
    protected lastName:string = ""
    protected dobYear:number = 0
    protected dobMonth:number = 0
    protected dobDay:number = 0

    protected dob:string = ""   //full dob. smartone user use
    protected hkidEnc:string = "" //smartone user use
    protected hkidEncDisplay:string = ""
    protected hkidEncPayment:string = ""
    
    protected msisdn:string = ""
    protected email:string = ""
    protected smcEmailEnc:string = ""
    protected dmOptInOut:boolean = false
    protected sRewardOptInOut:boolean = false

    protected smcAddress1:string = ""
    protected smcAddress2:string = ""

    protected smcDistrict:string = ""
    protected smcArea:string = ""

    protected paymentType:string = ""

    protected offlineRefNum:string = ""
    protected offlinePaymentType:OfflinePaymentType = OfflinePaymentType.CASH
    protected offlinePayDeposit:string = ""

    protected offlineApprover:string = "" //for offline portal, if approval rejected and reject reason = D.
    protected offlineApprovePass:string = ""
    protected fbbRejectReason:string = ""
    
    //protected preapprovalState:string = ""

    //protected hkidStart:string = ""  //purposely not save.
    //protected hkidMid:string = ""  //purposely not save.
    //protected hkidEnd:string = ""  //purposely not save.

    protected hometelApproveState:string = ""
    protected fbbApproveState:string = ""
    
    protected rawIDValue:string = ""

    protected originalISP:CurrentISP = CurrentISP.HKBN


    protected getData() {
        return {
            customerType: this.customerType,
            customerNumber: this.customerNumber,
            fbbNumber: this.fbbNumber,
            idType: this.idType,
            title: this.title,
            firstName: this.firstName,
            lastName:this.lastName,
            dobYear:this.dobYear,
            dobMonth:this.dobMonth,
            dobDay:this.dobDay,
            dob:this.dob,
            hkidEnc:this.hkidEnc,
            hkidEncDisplay:this.hkidEncDisplay,
            hkidEncPayment:this.hkidEncPayment,

            msisdn:this.msisdn,
            email:this.email,
            smcEmailEnc: this.smcEmailEnc,
            dmOptInOut:this.dmOptInOut ? 'Y' : 'N',
            sRewardOptInOut:this.sRewardOptInOut ? 'Y' : 'N',
            //preapprovalState:this.preapprovalState,
            originalISP:this.originalISP,
            smcAddress1:this.smcAddress1,
            smcAddress2:this.smcAddress2,

            smcDistrict:this.smcDistrict,
            smcArea:this.smcArea,
            hometelApproveState:this.hometelApproveState,
            fbbApproveState:this.fbbApproveState,

            rawIDValue: this.rawIDValue,   
            paymentType: this.paymentType,     
            offlineRefNum:this.offlineRefNum,    

            offlinePaymentType:this.offlinePaymentType,
            offlinePayDeposit:this.offlinePayDeposit,

            offlineApprover:this.offlineApprover,
            offlineApprovePass:this.offlineApprovePass,
            fbbRejectReason:this.fbbRejectReason,

        }
    }
    protected setData(data: any) {
        this.customerType = data.customerType ?? CustomerType.MASS
        this.customerNumber = data.customerNumber ?? ''
        this.fbbNumber = data.fbbNumber ?? ''
        var idType = data.idType ?? ''
        if ( idType == 'I' ){
            idType = 'hkid'
        }
        this.idType = idType ?? IDType.hkid
        
        this.title = data.title ?? ''

        this.firstName = data.firstName ?? ''
        this.paymentType = data.paymentType ?? ''
        this.lastName = data.lastName ?? ''
        this.dobYear = data.dobYear ?? 0
        this.dobMonth = data.dobMonth ?? 0
        this.dobDay = data.dobDay ?? 0
        this.dob = data.dob ?? ''
        this.hkidEnc = data.hkidEnc ?? ''
        this.hkidEncDisplay = data.hkidEncDisplay ?? ''
        this.hkidEncPayment = data.hkidEncPayment ?? ''
        
        this.msisdn = data.msisdn ?? ''
        this.email = data.email ?? ''
        this.smcEmailEnc = data.smcEmailEnc ?? ''
        this.dmOptInOut = data.dmOptInOut === 'Y'
        this.sRewardOptInOut = data.sRewardOptInOut === 'Y'
        //this.preapprovalState = data.preapprovalState
        this.originalISP = data.originalISP

        this.smcAddress2 = data.smcAddress2
        this.smcAddress1 = data.smcAddress1

        this.smcDistrict = data.smcDistrict
        this.smcArea = data.smcArea

        this.hometelApproveState = data.hometelApproveState
        this.fbbApproveState = data.fbbApproveState
        this.offlineRefNum = data.offlineRefNum

        this.offlinePaymentType = data.offlinePaymentType ?? OfflinePaymentType.CASH
        this.offlinePayDeposit = data.offlinePayDeposit

        this.offlineApprover = data.offlineApprover
        this.offlineApprovePass = data.offlineApprovePass
        this.fbbRejectReason = data.fbbRejectReason
        //this.hkidStart = ""  //purposely not save.
        //this.hkidMid = ""  //purposely not save.
        //this.hkidEnd = ""  //purposely not save.


    }

    public replaceData(p:UserProfile){
        this.setData(p.getData())
    }

    protected getDefaultData() {

        return {
            customerType: CustomerType.MASS,
            customerNumber: "",
            fbbNumber: "",
            idType: IDType.hkid,
            title: "",
            firstName: "",
            lastName:"",
            dobYear:0,
            dobMonth:0,
            dobDay:0,
            dob:'',
            hkidEnc:'',
            hkidEncDisplay:'',
            hkidEncPayment:'',

            msisdn:"",
            email:"",
            smcEmailEnc: '',
            dmOptInOut:false,
            sRewardOptInOut:false,
            //preapprovalState:'',
            originalISP:'',
            smcAddress1:'',
            smcAddress2:'',
            smcDistrict:'',
            smcArea:'',
            hometelApproveState:'',
            fbbApproveState:'',
            paymentType: '',
            offlineRefNum:'',
            offlinePaymentType:OfflinePaymentType.CASH,
            offlinePayDeposit:'',
            
            offlineApprover:'',
            offlineApprovePass:'',
            fbbRejectReason:'',
        }

    }

    getCustomerType() : CustomerType{
        return this.customerType
    }

    public setNewNewUserProfile(msisdn:string, customerNum:string, fbbNum:string,
                                title:string, firstName:string, lastName:string,
                                email:string, 
                                idType:IDType,
                                oriIsp:CurrentISP, 
                                hkidEncDisplay:string, hkidEncPayment:string,
                                dobYear:string, dobMonth:string, dobDay:string,
                                hometelApproveState:string, fbbApproveState:string,
                                offlineRefNum:string,
                                offlinePaymentType:OfflinePaymentType,
                                offlinePayDeposit:string, 
                                offlineApprover:string,
                                offlineApprovePass:string,
                                fbbRejectReason:string,  ){

        this.msisdn = msisdn
        this.customerNumber = customerNum
        this.fbbNumber = fbbNum    //subscriberNumber

        this.title = title
        this.firstName = firstName
        this.lastName = lastName

        this.idType = idType
        this.email = email

        this.dmOptInOut = false
        this.sRewardOptInOut = false

        this.originalISP = oriIsp
        
        this.hkidEncDisplay = hkidEncDisplay
        this.hkidEncPayment = hkidEncPayment

        this.hometelApproveState = hometelApproveState
        this.fbbApproveState = fbbApproveState

        //This is specific for New New user
        this.customerType = CustomerType.MASS
        this.dobYear = parseInt(dobYear)
        this.dobMonth = parseInt(dobMonth)
        this.dobDay = parseInt(dobDay)

        var dobMonthStr = (dobMonth < 10 && dobMonth.length < 2) ? ('0' + dobMonth) : dobMonth
        var dobDayStr = (dobDay < 10 && dobDay.length < 2) ? ('0' + dobDay) : dobDay

        this.dob = this.dobYear + '-' + dobMonthStr + '-' + dobDayStr

        this.offlineRefNum = offlineRefNum
        
        this.offlinePaymentType = offlinePaymentType ?? OfflinePaymentType.CASH
        this.offlinePayDeposit = offlinePayDeposit

        this.offlineApprover = offlineApprover
        this.offlineApprovePass = offlineApprovePass
        this.fbbRejectReason = fbbRejectReason

    }


    public setOfflineRefNum(refNum:string) {
        this.offlineRefNum = refNum
    }

    public getOfflineRefNum() : string{
        return (this.offlineRefNum == null ? '' : this.offlineRefNum ) + ''
    }

    public getOfflinePaymentType() : OfflinePaymentType{
        return this.offlinePaymentType
    }

    public setOfflinePaymentType(type:OfflinePaymentType) {
        this.offlinePaymentType = type ?? OfflinePaymentType.CASH
    }

    public setOfflinePayDeposit(deposit:string){
        this.offlinePayDeposit = deposit
    }

    public getOfflinePayDeposit() : string{
        return (this.offlinePayDeposit == null ? '' : this.offlinePayDeposit ) + ''
    }

    public cleanOfflineApprover(){
        this.offlineApprover = ''
        this.offlineApprovePass = ''
    }

    public setOfflineApprover(approver:string){
        this.offlineApprover = approver
    }

    public getOfflineApprover() : string{
        return (this.offlineApprover == null ? '' : this.offlineApprover ) + ''
    }

    public setOfflineApprovePass(pass:string){
        this.offlineApprovePass = pass
    }

    public getOfflineApprovePass() : string{
        return (this.offlineApprovePass == null ? '' : this.offlineApprovePass ) + ''
    }

    public setFbbRejectReason(reason:string){
        this.fbbRejectReason = reason
    }

    public getFbbRejectReason() : string{
        return (this.fbbRejectReason == null ? '' : this.fbbRejectReason ) + ''
    }
    

    public printNewNewUserProfile(){
        var p = {
            msisdn:this.msisdn,
            customerNumber: this.customerNumber,
            fbbNumber: this.fbbNumber,

            title: this.title,
            firstName: this.firstName,
            lastName:this.lastName,

            email:this.email,
            idType: this.idType,

            dmOptInOut:this.dmOptInOut,
            sRewardOptInOut:this.sRewardOptInOut,
            originalISP:this.originalISP,

            hometelApproveState:this.hometelApproveState,
            fbbApproveState:this.fbbApproveState,

            hkidEncDisplay:this.hkidEncDisplay,
            hkidEncPayment:this.hkidEncPayment,

            customerType: this.customerType,
            dob:this.dob,
            dobYear:this.dobYear,
            dobMonth:this.dobMonth,
            dobDay:this.dobDay,
            paymentType: this.paymentType,

            offlineRefNum:this.offlineRefNum,
            offlinePaymentType:this.offlinePaymentType,
            offlinePayDeposit:this.offlinePayDeposit,

            offlineApprover: this.offlineApprover,
            offlineApprovePass: this.offlineApprovePass,
            fbbRejectReason: this.fbbRejectReason,


        }
        log.dot('new new user Profile', p)
        
    }


    public setSmartoneUserProfile(msisdn:string, customerNum:string, fbbNum:string, 
                                    title:string, firstName:string, lastName:string, 
                                    email:string, smcEmailEnc:string,
                                    idType:IDType, 
                                    dmOptInOut:boolean, sRewardOptInOut:boolean,
                                    oriIsp:CurrentISP, 
                                    hkidEnc:string, hkidEncDisplay:string, dob:string,
                                    hkidEncPayment:string,
                                    smcAddress1:string, smcAddress2:string,
                                    smcDistrict:string, smcArea:string,
                                    hometelApproveState:string, fbbApproveState:string, paymentType:string,
                                    offlineRefNum:string,
                                    offlinePaymentType:OfflinePaymentType,
                                    offlinePayDeposit:string, 
                                    offlineApprover:string,
                                    offlineApprovePass:string,
                                    fbbRejectReason:string,){

        this.msisdn = msisdn
        this.customerNumber = customerNum
        this.fbbNumber = fbbNum    //subscriberNumber
        this.title = title
        this.firstName = firstName
        this.lastName = lastName
        
        this.email = email
        this.smcEmailEnc = smcEmailEnc
        this.idType = idType

        this.dmOptInOut = dmOptInOut
        this.sRewardOptInOut = sRewardOptInOut
        this.originalISP = oriIsp
        this.hkidEncDisplay = hkidEncDisplay
        this.hkidEncPayment = hkidEncPayment

        this.hometelApproveState = hometelApproveState
        this.fbbApproveState = fbbApproveState

        //This is specific for smartone user
        this.customerType = CustomerType.SMC
        this.dob = dob
        this.hkidEnc = hkidEnc

        this.smcAddress1 = smcAddress1
        this.smcAddress2 = smcAddress2
        this.smcDistrict = smcDistrict
        this.smcArea = smcArea
        this.paymentType = paymentType

        this.offlineRefNum = offlineRefNum

        this.offlinePaymentType = offlinePaymentType ?? OfflinePaymentType.CASH
        this.offlinePayDeposit = offlinePayDeposit

        this.offlineApprover = offlineApprover
        this.offlineApprovePass = offlineApprovePass
        this.fbbRejectReason = fbbRejectReason

    }

    public printSmcUserProfile(){
        var p = {
            customerNumber: this.customerNumber,
            fbbNumber: this.fbbNumber,
            idType: this.idType,
            title: this.title,
            firstName: this.firstName,
            lastName:this.lastName,
            smcAddress1:this.smcAddress1,
            smcAddress2:this.smcAddress2,
            smcDistrict:this.smcDistrict,
            smcArea:this.smcArea,

            hometelApproveState:this.hometelApproveState,
            fbbApproveState:this.fbbApproveState,

            msisdn:this.msisdn,
            email:this.email,
            smcEmailEnc: this.smcEmailEnc,
            dmOptInOut:this.dmOptInOut,
            sRewardOptInOut:this.sRewardOptInOut,
            hkidEncDisplay:this.hkidEncDisplay,

            customerType: this.customerType,
            dob:this.dob,
            hkidEnc:this.hkidEnc,
            originalISP:this.originalISP,
            paymentType:this.paymentType,
            
            offlineRefNum:this.offlineRefNum,
            offlinePaymentType:this.offlinePaymentType,
            offlinePayDeposit:this.offlinePayDeposit,
            
            offlineApprover: this.offlineApprover,
            offlineApprovePass: this.offlineApprovePass,
            fbbRejectReason: this.fbbRejectReason,

        }
        log.dot('SMC User Profile', p)
    }

    public getTitle() : string {
        return this.title + ''
    }

    public getSmcEmailEnc() : string {
        return this.smcEmailEnc + ''
    }

    public getFirstName() : string {
        return this.firstName + ''
    }

    public getPaymentType() : string {
        return this.paymentType + ''
    }
    
    public getLastName() : string {
        return this.lastName + ''
    }

    public getMsisdn() : string {
        return this.msisdn + ''
    }

    public getEmail() : string {
        return this.email + ''
    }
    
    public getSmcEmailEnc() : string {
        return this.smcEmailEnc + ''
    }
    

    public getHkidDisplay() : string {
        return this.hkidEncDisplay + ''
    }

    public getIdType() : IDType {
        return this.idType
    }

    public setIdType(idStr:string){
        if ( idStr == 'I' || idStr == 'hkid'){
            this.idType = IDType.hkid
        }
        else{
            this.idType = IDType.passport
        }
    }

    public getIdRawValue() : string {
        return this.rawIDValue + ''
    }

    public getDobYearDispay() : string {
        return this.dobYear === 0 ? '': this.dobYear.toString()
    }
    public getDobMonthDispay() : string {
        return this.dobMonth === 0 ? '': this.dobMonth.toString()
    }
    public getDobDayDispay() : string {
        return this.dobDay === 0 ? '': this.dobDay.toString()
    }

    public getDobDisplay() : string {
        return this.dob + '' //+ ' (todo:format)'
    }

    public getCurrentIsp() : CurrentISP {
        return this.originalISP
    }

    public getHkidPayment() : string {
        return this.hkidEncPayment + ''
    }

    public getDobApiFormat() : string {
        return this.dob + '' //todo?
    }

    public getCustomerNumber() : string {
        return this.customerNumber + ''
    }

    public getFbbNumber() : string {
        return this.fbbNumber + ''
    }

    public setFbbNumber(fbbNum:string) {
        this.fbbNumber = fbbNum
    }

    public cleanFbbNumber() {
        this.fbbNumber = ''
    }

    public getSmcAddress1() : string {
        return this.smcAddress1 + ''
    }

    public getSmcAddress2() : string {
        return this.smcAddress2 + ''
    }

    public getSmcDistrict() : string {
        return this.smcDistrict + ''
    }

    public getSmcArea() : string {
        return this.smcArea + ''
    }

    public getHometelApproveState() : string {
        return this.hometelApproveState + ''
    }

    public getFbbApproveState() : string {
        return this.fbbApproveState + ''
    }
    
}