//import React from 'react';
import {Address} from "../model/fbb/session/Address"
import {Plan} from "../model/fbb/session/Plan"
import {AllCoverage} from "../model/fbb/session/AllCoverage"
import {UserProfile} from "../model/fbb/session/UserProfile"
import {FbbError} from "../model/fbb/session/FbbError"
import * as log from '../common/js/appLogger'
import { AllAddOn } from "../model/fbb/session/AllAddOn"
import { Installation } from "../model/fbb/session/Installation"
import * as apiService from "../common/js/apiService"
import {AppData} from "../data/AppData"
//import {CustomerType} from "../model/fbb/FbbEnum"


export class UserData {
    
    static address:Address | null = null
    static allCoverage:AllCoverage | null = null
    static plan:Plan
    static userProfile:UserProfile  | null = null
    static fbbError:FbbError | null = null
    static allAddon:AllAddOn | null = null
    static installation:Installation | null = null

    static tempPlanResult:any | null = null

    static channel:string | null = null //follow entry point? not save to session
    //static availablePlansJson:any | null = null //not save to session. API return result


    //add a class for storing page logic that need to save to session.
    //e.g. sorry page's reason

    static clearUserInput(){
        log.i('clearUserInput')
        UserData.address?.cleanStorage()
        UserData.allCoverage?.cleanStorage()
        UserData.plan?.cleanStorage()
        UserData.userProfile?.cleanStorage()
        UserData.fbbError?.cleanStorage()
        UserData.allAddon?.cleanStorage()
        UserData.installation?.cleanStorage()
        //UserData.availablePlansJson = null
    }
    static init(){
        log.it('UserData', 'init')
        UserData.address = new Address()
        UserData.allCoverage = new AllCoverage()
        UserData.plan = new Plan()
        UserData.channel = null
        UserData.userProfile = new UserProfile()
        UserData.fbbError = new FbbError()
        UserData.allAddon = new AllAddOn()
        UserData.installation = new Installation()
        //UserData.availablePlansJson = null

    }

    static load(){
        UserData.init()
        UserData.address?.load()
        UserData.allCoverage?.load()
        UserData.userProfile?.load()
        UserData.plan?.load()
        UserData.fbbError?.load()
        UserData.allAddon?.load()
        UserData.installation?.load()
        log.ot('UserData.load fbbError', UserData.fbbError)
        
        AppData.status.printOfflineConfig('UserData')
        
    }

}