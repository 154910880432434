
//import {ASession} from '../../common/ASession'
//import { Operator, Technology, Bandwidth, CustomerType } from './FbbEnum'
//import * as log from '../../common/js/appLogger'
import { SpecialOffer } from './SpecialOffer'
import { IModelUtil } from '../common/IModelUtil'

export class SpecialOfferGroup implements IModelUtil{
    

    protected maxSelectCount:Number = 1
    protected selected:SpecialOffer | null = null
    protected list:Array<SpecialOffer> = []

    /*
    constructor(maxSelectCount:Number, list:Array<SpecialOffer>){
        this.maxSelectCount = maxSelectCount
        this.list = list
        this.selected = null
    }
    */
    
    public getMaxSelectCount() : Number {
        return this.maxSelectCount
    }

    public setMaxSelectCount(count:Number)  {
        this.maxSelectCount = count
    }

    public setSpecialOfferList(list:Array<SpecialOffer>)  {
        this.list = list
    }

    public getSelectedSpecialOffer() : SpecialOffer | null {
        return this.selected
    }

    public getSpecialOfferList() : Array<SpecialOffer> {
        return this.list
    }

    public setSelected(target:SpecialOffer) : Boolean {

        for(var anOffer of this.list){
            if ( anOffer.getMarketingCode() === target.getMarketingCode() ){
                this.selected = anOffer
                return true
            }
        }
        return false
    }

    parseApiResult(data: any): void {
        throw new Error("NA")
    }

    convertToJson() {

        var listJsonArray:any = []
        for(var anOffer of this.list ){
            listJsonArray.push(anOffer.convertToJson())
        }

        return {
            maxSelectCount: this.maxSelectCount,
            selected: this.selected == null ? null : this.selected.convertToJson(),
            list: listJsonArray
        }
    }

    convertFromJson(json: any): void {
        
        this.maxSelectCount = json.maxSelectCount
        if ( json.selected == null ){
            this.selected = null
        }
        else{
            this.selected = new SpecialOffer()
            this.selected.convertFromJson(json.selected)
        }

        var list:Array<SpecialOffer> = []
        json.list?.forEach((anOffer: any) => {
            var obj = new SpecialOffer()
            obj.convertFromJson(anOffer)
            list.push(obj)
        });

        this.list = list
        
    }

}