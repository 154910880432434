

import { Operator } from '../FbbEnum'
import { Coverage } from '../Coverage';
import { AddOnHometel } from './AddOnHometel'
import { AddOnMyTV } from './AddOnMyTV'
import { AddOnRouter } from './AddOnRouter'
// import { AddOnKKBox } from './AddOnKKBox'
import { AddOnNorton } from './AddOnNorton'
import { AddOnHomePhone } from './AddOnHomePhone'
import { AddOn } from './AddOn'
import * as log from '../../../common/js/appLogger'
import { AddOnHomePhonePlus } from './AddOnHomePhonePlus';
import { AddOnDummy } from './AddOnDummy';
//import { Plan } from '../session/Plan'

//This is a special class that originally wanna put the methods to AddOn Class. But doing so the app will not able to run normally.
//If you have solution pls feel free to update.
export class AddOnUtil {

    static getLandingAddOnList(json:any) : Array<AddOn>{

        var list:Array<AddOn> = []
        list.push(new AddOnHomePhone())
        list.push(new AddOnMyTV())
        list.push(new AddOnRouter())
        // list.push(new AddOnKKBox())
        list.push(new AddOnNorton())
        // list.push(new AddOnDummy())

        log.dt('[SPU7]', 'getLandingAddOnList')
        for(var addon of list){
            var j = AddOnUtil.findJson(json, addon.getKey())
            addon.parseConfig(j)
        }

        return list
    }

    static getSelectPlanAddOnList(json:any) : Array<AddOn>{

        var list:Array<AddOn> = []
        list.push(new AddOnHomePhonePlus())
        list.push(new AddOnHometel())
        list.push(new AddOnMyTV())
        //list.push(new AddOnRouter())
        // list.push(new AddOnKKBox())
        list.push(new AddOnNorton())

        log.dt('[SPU7]', 'getSelectPlanAddOnList')
        for(var addon of list){
            var j = AddOnUtil.findJson(json, addon.getKey())
            addon.parseConfig(j)
        }

        return list
    }

    static findJson(jsonArray:any, key:string) : any{ 
        var landing = jsonArray['landing'] ?? []
        let jsonGroups:any = {}
        //log.ot('findJson', landing)
        for(var anAddonJson of landing){
            //log.dt('findJson', anAddonJson['id'])
            if ( ( anAddonJson['id'] ?? '_') === key || 
                    ( anAddonJson['id'] ?? '_').indexOf(key + '___') != -1 ){
                //return anAddonJson
                jsonGroups[anAddonJson['id']] = anAddonJson
            }
        }
        //log.ot('jsonGroups', jsonGroups)
        return jsonGroups
    }

    // json: addon.json
    // addOnArr: current addon allowed
    static getConfigAddOnList(json:any, addOnAr:Array<AddOn>) : Array<AddOn>{
        var list:Array<AddOn> = addOnAr
        log.dt('[SPU7]', 'getConfigAddOnList')
        for(var addon of list){
            //log.it('getConfigAddOnList', addon.getKey())
            var j = AddOnUtil.findJson(json, addon.getKey())
            addon.parseConfig(j)
        }

        return list
    }

    static getAddOnList(operator:Operator, tech:String, 
                        hkbnCov:Coverage, contractMonth:number) : Array<AddOn>{

        log.it('getAddOnList', operator)
        //log.i('getAddOnList|hkbnCov='+ hkbnCov.toString() + '|contractMonth=' + contractMonth );

        var list:Array<AddOn> = []
        
        list.push(new AddOnHomePhonePlus()) //all
        if ( operator === Operator.hkbn ){
            //Home Phone not ready at phase 1
            if ( tech.toUpperCase() === 'ME' ){
                if ( hkbnCov.getHaveHometelPort() ){
                    list.push(new AddOnHometel())
                }
            }
            else{
                list.push(new AddOnHometel())
            }
            //list.push(new AddOnMyTV())
            list.push(new AddOnRouter())
            if(contractMonth !== 12){
                list.push(new AddOnNorton())
            }
            
            //list.push(new AddOnKKBox())
            
        }
        else if ( operator === Operator.hgc ){
            //list.push(new AddOnMyTV())      
            list.push(new AddOnRouter())  
            if(contractMonth !== 12){
                list.push(new AddOnNorton())
            }
            //list.push(new AddOnKKBox())
        }
        else if ( operator === Operator.icable ){
            //list.push(new AddOnMyTV())
            list.push(new AddOnRouter())
            
            if(contractMonth !== 12){
                list.push(new AddOnNorton())
            }
            //list.push(new AddOnKKBox())
        }
        return list
    }

}