

import * as wording from '../../../common/js/wording'
//import {Coverage} from './Coverage'
import {ASession} from '../../common/ASession'
import { IModelUtil } from '../../common/IModelUtil'
//import { log } from '../common/js/appLogger'

export class Address extends ASession implements IModelUtil {
    
    protected sessionKey:string = "Address"

    protected addrId:string | null = null
    protected flat:string | null = null
    protected floor:string | null = null

    protected buildingNameEn:string | null = null
    protected buildingNameZh:string | null = null

    protected districtEn:string | null = null
    protected areaEn:string | null = null

    protected hkbnAddrId:string | null = null
    protected hgcAddrId:string | null = null
    protected icableAddrId:string | null = null


//=================================================================================

    constructor(){
        super()
        this.setData(this.getDefaultData())
    }

    
    protected getDefaultData() : any{
        return {
            addrId: null,
            buildingNameEn: null,
            buildingNameZh: null,
            flat: null,
            floor: null,
            districtEn:null,
            areaEn:null,
            
            hkbnAddrId: null,
            hgcAddrId: null,
            icableAddrId: null,
        }
    }

    protected getData() : any {
        //throw new Error("Method not implemented.")
        return {
            addrId:this.addrId ?? null,
            flat:this.flat ?? null,
            floor:this.floor ?? null,
            buildingNameEn:this.buildingNameEn ?? null,
            buildingNameZh:this.buildingNameZh ?? null,
            
            districtEn:this.districtEn ?? null,
            areaEn:this.areaEn ?? null,
            
            hkbnAddrId: this.hkbnAddrId ?? null,
            hgcAddrId: this.hgcAddrId ?? null,
            icableAddrId: this.icableAddrId ?? null,
        }

    }
    protected setData(data: any) {
        
        this.addrId = data.addrId
        this.buildingNameEn = data.buildingNameEn
        this.buildingNameZh = data.buildingNameZh
        this.flat = data.flat
        this.floor = data.floor

        this.districtEn = data.districtEn
        this.areaEn = data.areaEn
        
        this.hkbnAddrId = data.hkbnAddrId
        this.hgcAddrId = data.hgcAddrId
        this.icableAddrId = data.icableAddrId

        //parseInt(null) returns NaN but +null returns 0 

        //don't call save() here.
    }

    //=================================================================================

    setAddressId(id:string | null){
        this.addrId = id
    }

    getAddressId(): string | null { return (this.addrId ?? '') + '' }

    
    setHkbnAddressId(id:string | null){
        this.hkbnAddrId = id
    }

    getHkbnAddressId(): string | null { return this.hkbnAddrId + '' }
    
    setHGCAddressId(id:string | null){
        this.hgcAddrId = id
    }

    getHGCAddressId(): string | null { return this.hgcAddrId + '' }
    
    setICableAddressId(id:string | null){
        this.icableAddrId = id
    }

    getICableAddressId(): string | null { return this.icableAddrId + '' }

    setNameEn(name:string | null){
        this.buildingNameEn = name
    }

    setNameZh(name:string | null){
        this.buildingNameZh = name
    }

    getDistrictEn(): string | null { return this.districtEn + '' }


    setDistrictEn(dis:string | null){
        this.districtEn = dis
    }

    getAreaEn(): string | null { return this.areaEn + '' }


    setAreaEn(area:string | null){
        this.areaEn = area
    }

    getName(lang: string | null) : string {
        var targetLang = lang ?? wording.getLang()
        if ( targetLang == wording.ENGLISH ){
            return this.buildingNameEn + ''
        }
        else{
            return this.buildingNameZh + ''
        }
    }

    getFlat(): string | null { return this.flat + '' }

    setFloor(_floor:string | null){
        this.floor = _floor
    }

    setFlat(_flat:string | null){
        this.flat = _flat
    }

    getFloor(): string | null { return this.floor + ''}

    static getFloorDisplay(floor:string | null, lang:string): string | null {
        if ( 'ROOF' === floor?.toUpperCase() ){
            if ( wording.ENGLISH === lang ){
                return 'Roof'
            }
            else{
                return '天台'
            }
        }
        return floor + ''
    }

    getAddressDisplay(lang:string | null) : string | null {
        var targetLang = lang ?? wording.getLang()
        if ( targetLang === wording.ENGLISH ){
            return this.getFlatFloorDisplay(lang) + ', ' + this.buildingNameEn
        }
        else{
            return this.buildingNameZh + ' ' + this.getFlatFloorDisplay(lang)
        }
    }

    static getFlatDisplay(flat:string | null ): string | null {
        return flat + ''
    }

    //if lang is null, got from stored lang
    getFlatFloorDisplay(lang:string | null): string {

        var targetLang = lang ?? wording.getLang()
        // eslint-disable-next-line eqeqeq
        if ( targetLang == wording.ENGLISH ){
            return 'Room ' + Address.getFlatDisplay(this.flat) + ', Floor ' + Address.getFloorDisplay(this.floor, targetLang);
        }
        else{
            return '樓 層 ' + Address.getFloorDisplay(this.floor, targetLang) + ' 單 位 ' + Address.getFlatDisplay(this.flat);
        }
    }

    getBuildingNameDisplay(lang:string | null):string | null {
        var targetLang = lang ?? wording.getLang()
        // eslint-disable-next-line eqeqeq
        if ( targetLang == wording.ENGLISH ){
            return this.buildingNameEn;
        }
        else{
            return this.buildingNameZh;
        }
    }
    


//=================================================================================
    //interface IModelUtil

    //No direct API result for Address.
    parseApiResult(data: any): void {
        throw new Error("Not Applicable.")
    }

    convertToJson() {
        var json = this.getData()
        return json
    }

    convertFromJson(json:any) : void {
        this.setData(json)
    }


}