//import * as util from './util.js'
// react-ga  
import * as log from './appLogger'
import {Config} from '../../data/Config';

var GA_ID_DEV = '';
var isGACreated = false

var gaPrefix = "/reactJsFbb3In1"
var sendPath = ''

declare global {
    interface Window { ga: any; }
}

function isEnabled(){
    return Config.enableGA
}

//call GA create. GA lib is included in index.js
function initGA(){
    if ( isEnabled() && !isGACreated ) {

        window.ga = window.ga || {};
        GA_ID_DEV = Config.gaId;
        log.it('GA', 'init GA')
        window.ga('create', GA_ID_DEV, 'auto');
        isGACreated = true;
    }
}

export function logPageView(name: string){
    window.ga = window.ga || {};
    log.it('GA', '[logPageView]' + name)
    
    //Check is environment enable GA
    if ( !isEnabled() ){
        return
    }
    initGA()

    sendPath = gaPrefix + name;
    
    //Log page view.
    log.it('GA', '[logPageView] ga send:' + sendPath)
    window.ga('send', 'pageview', sendPath);

}