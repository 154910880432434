
import * as log from '../common/js/appLogger'
//import {ISessionHandler} from './ISessionHandler'
import * as util from '../common/js/util'

//actual values see _dev/config.ts or _local/config.ts.
export class Config {
    
    static readonly VERSION = '3.0.30.168'

    //static readonly OVERRIDE_SCHEME:string  = "DEV"  //if empty, it will check util.isDev() etc checking

    static PASSPORT_PLAN_DEPOSIT = 1000
    static PASSPORT_HOMETEL_DEPOSIT = 1000

    static readonly CASH_PLAN_DEPOSIT = 1500
    static readonly CASH_HOMETEL_DEPOSIT = 360

	static readonly VAS_MOBILE_STYLE_MAX_WIDTH = 599 //959 //Config.mobileMaxWidth //

    //==============================================

    static readonly SERVER_CHECK_PREPROD:RegExp    =  /shopstage.smartone.com/
    static readonly SERVER_CHECK_PROD:RegExp    =  /shop.smartone.com/
    static readonly SERVER_CHECK_STAGING:RegExp    =  /webstage|waptest|testcloudstorage|wwwtest/
    
    //(UA-20788189-5) and GA2 (UA-53905380-8
    static readonly GTM_ID_PROD:string = 'GTM-PDFMTR8'
    static readonly GTM_ID_DEV:string = 'GTM-KNG5KH7'
    //

    //==============================================

    static appmode:string   //for developer reference only
    static env:string   //for api param "environment"
    static enableGA:boolean
    static enableGTM:boolean
    static useMockupApi:boolean
    static useARMSDummyApi:boolean
    static removeUrlParams:boolean
    static enabledMissingFieldPage:boolean
    static enableLiveChat:boolean
    static GTM_ID:string = ''

    static supportOffline:boolean //offline portal. This is global flag. Final control flag is AppData.status.isOfflineMode().
    
    static printLog:number //0 = debug, > info > error
    static promptErrorToConsole:boolean // not prompt error, show to console log instead.

    static gaId:string
    static pathname:string
    static filepathname:string
    static urlPath:string

    static pcMaxWidth:number
    static mobileMaxWidth:number

    static ARMSDummyApiConfig = {
        projectId: 'FBB_3IN1',
        scenarioId: 'Online_Outbase'
    }

    static setup() : void{

        Config.supportOffline = true //don't update this flag when app running.

        if ( util.isLocal() ){
            this.loadLocal()
		} 
		else if ( util.isDev() ){
            this.loadDEV()
        }
        else if ( util.isStg() ){
            this.loadStg()
        }
        else if ( util.isPreProd() ){
            this.loadPreprod()
        }
        else if ( util.isProd() ){
            this.loadProd()
        }

        log.ot('APP VERSION', Config.VERSION)
    }

    private static loadLocal() {

        Config.appmode = "LOCAL"
        Config.enableGA = false
        Config.enableGTM = true
        Config.GTM_ID = Config.GTM_ID_DEV

        Config.useMockupApi = true
        Config.useARMSDummyApi = false
        Config.env = "local"


        Config.enabledMissingFieldPage = true
        Config.enableLiveChat = false
        Config.printLog = 0
        Config.removeUrlParams = false
        Config.pcMaxWidth = 1440
        Config.mobileMaxWidth = 959
        Config.promptErrorToConsole = false

        Config.gaId = "UA-112311459-11"
        Config.pathname = "/fbb"
        Config.filepathname = "/fbb"
        Config.urlPath = '/Fibre_Broadband/'
        //Config.pathname = "/fbb_react/Fibre_Broadband/web"
        //Config.filepathname = "/fbb_react/Fibre_Broadband/web"
        
        log.it('Config', 'loadLocal')
    }

    protected static loadDEV(){

        Config.appmode = "DEV"
        Config.env = "staging"
        Config.enableGA = false
        Config.enableGTM = true
        Config.GTM_ID = Config.GTM_ID_DEV
        Config.useMockupApi = true
        Config.useARMSDummyApi = false
        Config.enableLiveChat = false
        Config.removeUrlParams = false
        Config.printLog = 0
        Config.pcMaxWidth = 1440
        Config.mobileMaxWidth = 959
        Config.promptErrorToConsole = true
        Config.enabledMissingFieldPage = false

        Config.gaId = "UA-112311459-1"
        Config.pathname = "/Fibre_Broadband/Fibre_Broadband/web"
        Config.filepathname = "/Fibre_Broadband/Fibre_Broadband/web"
        Config.urlPath = '/Fibre_Broadband/'

        log.it('Config', 'loadDEV')
    }

    protected static loadStg(){

        Config.appmode = "STG"
        Config.env = "staging"
        Config.enableGA = false
        Config.enableGTM = true
        Config.GTM_ID = Config.GTM_ID_PROD
        Config.useMockupApi = false
        Config.useARMSDummyApi = false
        Config.enableLiveChat = true
        Config.removeUrlParams = false
        Config.printLog = 1
        Config.pcMaxWidth = 1440
        Config.mobileMaxWidth = 959
        Config.promptErrorToConsole = true
        Config.enabledMissingFieldPage = false

        Config.gaId = "UA-112311459-1"
        Config.pathname = "/Fibre_Broadband/web"
        Config.filepathname = "/Fibre_Broadband/web"
        Config.urlPath = '/Fibre_Broadband/'

        log.it('Config', 'loadSTG')
    }

    protected static loadPreprod(){

        Config.appmode = "PRE-PROD"
        Config.env = "pre-production"
        Config.enableGA = false
        Config.enableGTM = true
        Config.GTM_ID = Config.GTM_ID_PROD
        Config.useMockupApi = false
        Config.useARMSDummyApi = false
        Config.enableLiveChat = true
        Config.removeUrlParams = false
        Config.printLog = 1
        Config.pcMaxWidth = 1440
        Config.mobileMaxWidth = 959
        Config.promptErrorToConsole = true
        Config.enabledMissingFieldPage = false

        Config.gaId = "UA-112311459-1"
        Config.pathname = "/Fibre_Broadband/web"
        Config.filepathname = "/Fibre_Broadband/web"
        Config.urlPath = '/Fibre_Broadband/'

        log.it('Config', 'loadPreProd')
    }

    protected static loadProd(){

        Config.appmode = "PROD"
        Config.env = "production"
        Config.enableGA = false
        Config.enableGTM = true
        Config.GTM_ID = Config.GTM_ID_PROD
        Config.useMockupApi = false
        Config.useARMSDummyApi = false
        Config.enableLiveChat = true
        Config.removeUrlParams = false
        Config.printLog = 1
        Config.pcMaxWidth = 1440
        Config.mobileMaxWidth = 959
        Config.promptErrorToConsole = true
        Config.enabledMissingFieldPage = false

        Config.gaId = "UA-112311459-1"
        Config.pathname = "/Fibre_Broadband/web"
        Config.filepathname = "/Fibre_Broadband/web"
        Config.urlPath = '/Fibre_Broadband/'

        log.it('Config', 'loadProd')
    }

}
