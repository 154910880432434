// eslint-disable-next-line
/* eslint-disable */
import React from 'react';
import { Component , useState } from 'react';
//import logo from './logo.svg';
import './App.css';
import { withRouter } from "react-router-dom"
import { Route } from "react-router-dom"
import ViewController from './common/page/ViewController.jsx'
//import { thisExpression } from '@babel/types';

import * as util from './common/js/util'
import * as log from './common/js/appLogger'
import * as gtmLogger from './common/js/gtmLogger'
import {Config} from './data/Config'

import * as wording from './common/js/wording'
import { API } from './data/API';
import { AppData } from './data/AppData';
import {UserData} from "./data/UserData"



class App extends Component {
  
	constructor(props) {
		super(props);

		this.state = {
			webappX:0,
			winHeight:0,
			isPc: false,
	  	};
		  
		//pls maintain the init order
		Config.setup()
		API.setup()
		AppData.load()
		UserData.load()
		wording.loadLang()
		gtmLogger.initGTM()

		//UserData.address.cleanStorage()
		//log.i(UserData.address.getData())
		//UserData.address.setId('ssdfdf')
		
		log.it('App', 'App mode:' + Config.appmode)
		
		// Detect ios 11_x_x affected  
    	// NEED TO BE UPDATED if new versions are affected
    	var ua = navigator.userAgent,
    	iOS = /iPad|iPhone|iPod/.test(ua),
		iOS11 = /OS 11_/.test(ua),
		iOS13 = !(/OS 9_|OS 10_|OS 11_|OS 12_/.test(ua));
    	// ios 11 bug caret position
    	if ( iOS && iOS11 ) {
			// Add CSS class to body
			//document.getElementsByTagName("BODY")[0].classList.add('iosBugFixCaret')
		}

		if(iOS && iOS13){
			document.getElementsByTagName("HTML")[0].classList.add('w-safari-13')
		}


		//remove all query params.
		if ( Config.removeUrlParams ){
			if ( this.props.location.search != null && this.props.location.search.length > 0 ){
				this.props.history.replace(this.props.location.pathname)
			}
		}

		//if ( this.isSafari() && util.isIOS() ){
		//	document.getElementsByTagName("HTML")[0].classList.add('w-safari')
		//}

		//for FBB only: handle query params before #.
		//URL for fbb will be like this:
		//http://localhost:3000/index.jsp?a=d&v=fosdddd&ksdjfklg=09#/a/a5?s=d
		var queryParams = this.getFbbQueryParams()
		AppData.urlParams = queryParams
		log.ot('AppData.urlParams', AppData.urlParams)

		window.addEventListener("resize",function(e){	
			var x = this.calcWebappCotnainerX()
			var winWidth = window.innerWidth
			var winH = window.innerHeight
			var newIsPc = winWidth > Config.mobileMaxWidth
			var heightChanged = this.state.winHeight != winH
			//log.dt('resize', winWidth + ' > ' +  Config.mobileMaxWidth)
			if ( x != this.state.webappX || newIsPc != this.state.isPc || heightChanged ){
				this.setState({
					webappX:x,
					isPc: newIsPc,
					winHeight:winH
				});
			}
			//if ( this.isSafari() ){
			//	document.getElementsByTagName("HTML")[0].classList.add('w-safari')
			//}
		}.bind(this))

		this.getFbbQueryParams = this.getFbbQueryParams.bind(this)
		
		
		

		this.state.webappX = this.calcWebappCotnainerX()
		var winWidth = window.innerWidth
		this.state.isPc = winWidth > Config.mobileMaxWidth
		
		/*
		window.addEventListener('scroll', function(e){
			log.it('APPPPP', 'on scroll.')
		});
		*/


		const script = document.createElement("script");
		script.src = "https://use.typekit.net/foobar.js";
		script.async = true;
		document.body.appendChild(script);

	}


	getFbbQueryParams(checkEnd){
		
		//url = 'http://dev.vaka.com/Fibre_Broadband/jsp/Fibre_Broadband/english/index.jsp?a=ddddf&l=PAL&s=d#'
		var vars = util.getParamBeforeHash(checkEnd)
		return vars
	}


	calcWebappCotnainerX(){

		var w = window.innerWidth;
		//log.it('App','resize:' + w);	
		if ( w > Config.pcMaxWidth ){
			return (w - 1440) / 2
		}
		else{
			return 0
		}
	}


	componentWillUnmount() {
	}
	
	isSafari() {
		var ua = navigator.userAgent.toLowerCase(); 
		log.it('ua', ua)
		if (ua.indexOf('safari') != -1) { 
			if (ua.indexOf('chrome') > -1) {
				return false
			} 
			else {
				return true
			}
		}
		return false
	}

	render() {

		
		var webappClass = this.state.isPc ? ' pc ' : ' mobile '
		webappClass = webappClass + ' ' + (this.isSafari() ? ' w-safari ' : '')
		webappClass = webappClass + ' ' + (this.state.webappX == 0 ? '' : ' exceed ')
		if ( util.isLocal() ){
			webappClass = webappClass + ' local-dev '
		}

		var showMobileStyleVas = util.showMobileStyleVas()
		
		//for a2 - VAS addon box and detail box. this is differ from .mobile & .pc...
		webappClass = webappClass + ( showMobileStyleVas ? ' detail-mobile ' : ' detail-pc ' )
	

		var webappStyle={} //{left:this.state.webappX + 'px'}	
		return (
			<div id="webapp" className={webappClass} style={webappStyle}
				onTouchStart={this.handleTouchStart} 
				onTouchEnd={this.handleTouchEnd} 
				onTouchMove={this.handleTouchMove} >
				<Route path="/" render={(props) => (
						<ViewController {...props} isPc={this.state.isPc} 
						getFbbQueryParams={this.getFbbQueryParams} />
				)} />
			</div>
		);
	  }
}

export default withRouter(App);
